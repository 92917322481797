import React from 'react';
import { connect } from 'react-redux';
import { Radio } from 'antd';

//css imports
import 'antd/es/radio/style/css';

//action imports
import { radioOptionChanged, descriptionUpdate, clearDescriptionBox, changeDescription, } from "../actions/PanelActions";

//component imports
import { Button } from "./common/Index";

class AboutUsPanel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            aboutUsDescription: this.props.aboutUsDescription,
        };

        this.onAboutUsRadioChange = this.onAboutUsRadioChange.bind(this);
        this.onSaveAboutUsDescription = this.onSaveAboutUsDescription.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if(nextProps.aboutUsDescription !== this.props.aboutUsDescription) {
            this.setState(({ aboutUsDescription: this.props.aboutUsDescription }))
        }

    }

    onAboutUsRadioChange = e => {

        const radioFrom = 'aboutUsRadio';
        const value = e.target.value;
        this.props.radioOptionChanged(radioFrom, value);

    };

    onSaveAboutUsDescription() {
        const { aboutUsRadioValue, aboutUsDescription } = this.props;

        if (aboutUsDescription !== "") {
            this.props.changeDescription({ page: 'aboutUsPage', title: aboutUsRadioValue, value: aboutUsDescription });
        } else {
            alert("Please fill in description field");
        }
    }

    render() {
        return(
            <div className={"aboutUsPanel panel"}>

                <div className={"aboutUsContainer panelContainer"}>
                    <div>
                        <div>
                            <Radio.Group onChange={this.onAboutUsRadioChange} value={this.props.aboutUsRadioValue} size="large">
                                <Radio.Button value="aboutUs">About Us</Radio.Button>
                                <Radio.Button value="vision">Vision</Radio.Button>
                                <Radio.Button value="mission">Mission</Radio.Button>
                                <Radio.Button value="coreValues">Core Values</Radio.Button>
                                <Radio.Button value="ourTeam">Our Team</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>

                    <div className={"aboutUsDescription panelDescription"}>
                        <textarea
                            style={{
                                color: '#000',
                            }}
                            rows="10"
                            cols="100"
                            placeholder={ "Description" }
                            value={this.props.aboutUsDescription}
                            onChange={ e => this.props.descriptionUpdate({ prop: 'aboutUsDescription', value: e.target.value}) }
                        />
                    </div>

                    <Button
                        onPress={ () => this.onSaveAboutUsDescription() }
                        text={"Save Description"}
                    />
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    const { aboutUsRadioValue, aboutUsDescription } = state.panel;

    return {
        aboutUsRadioValue, aboutUsDescription
    }
};


export default connect(mapStateToProps, { radioOptionChanged, descriptionUpdate, clearDescriptionBox, changeDescription, })(AboutUsPanel);