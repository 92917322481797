import React, { Component } from 'react';


class LocationMap extends Component {

    render() {
        return (
            // Important! Always set the container height explicitly
            <div style={{ height: '100%', width: '100%', backgroundColor: "#333" }}>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1112.9948340962028!2d39.24742566110795!3d-6.764236533559606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNsKwNDUnNTIuMCJTIDM5wrAxNCc1My4wIkU!5e0!3m2!1sen!2stz!4v1573633269480!5m2!1sen!2stz"
                    width="100%" height="100%" frameBorder="0" style={{ border: 0}} allowFullScreen>

                </iframe>
            </div>
        );
    }
}

export default LocationMap;