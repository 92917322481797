import {CREDENTIAL_UPDATE, LOGIN_USER, LOGIN_USER_FAIL, LOGIN_USER_SUCCESS, LOGOUT_ADMIN} from "../actions/Types";

const INITIAL_STATE = {
    email: "",
    password: "",
    admin: false,
    error: "",
    loading: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER_FAIL:
            return { ...state, error: "Authentication Error", loading: false };
        case LOGOUT_ADMIN:
            return { ...state, admin: false, loading: false };
        case LOGIN_USER_SUCCESS:
            return { ...state, admin: true, loading: false };
        case LOGIN_USER:
            return { ...state, loading: true };
        case CREDENTIAL_UPDATE:
            return { ...state, [ action.payload.prop ]: action.payload.value };
        default:
            return state;
    }
};