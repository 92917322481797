import firebase from 'firebase';

import {
    CREDENTIAL_UPDATE,
    LOGIN_USER, LOGIN_USER_FAIL, LOGIN_USER_SUCCESS, LOGOUT_ADMIN, SIGNUP_USER_FAIL
} from './Types';

export const credentialUpdate = ({prop, value }) => {
    return {
        type: CREDENTIAL_UPDATE,
        payload:  {prop, value }
    }
};

export const loginUser = ({ email, password, history }) => {
    return (dispatch) => {
        dispatch({ type: LOGIN_USER });

        firebase.auth().signInWithEmailAndPassword(email, password)
            .then((user) => {
                loginUserSuccess(dispatch, user);
                history.push('/admin-panel');
            })
            .catch(()=> loginUserFail(dispatch))
    };
};

export const signupUser = ({ email, password }) => {
    return (dispatch) => {
        dispatch({ type: LOGIN_USER});

        firebase.auth().createUserWithEmailAndPassword(email, password)
            .then((user) => {
                signUpUserSuccess( dispatch, user );
            })
            .catch(()=> signupUserFail(dispatch))
    };
};

const loginUserSuccess = ( dispatch, user ) => {
    dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: user
    });
};

const signUpUserSuccess = ( dispatch, user ) => {
    dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: user
    });
};

const loginUserFail = (dispatch) => {
    console.log('sign in failed');
    dispatch({
        type: LOGIN_USER_FAIL
    });
};

const signupUserFail = (dispatch) => {
    dispatch({
        type: SIGNUP_USER_FAIL
    });
};

export const logOutAdmin = () => {
    return {
        type: LOGOUT_ADMIN
    }
};