import { combineReducers } from 'redux';

//reducer imports
import AdminReducer from './AdminReducer';
import PanelReducer from './PanelReducer';
import NavReducer from './NavReducers';
import ContactReducer from './ContactReducer';

export default combineReducers({
    admin: AdminReducer,
    panel: PanelReducer,
    nav: NavReducer,
    contact: ContactReducer,
})