import React from 'react';
import { connect } from 'react-redux';
import scrollToComponent from 'react-scroll-to-component';

//file imports
import cctv from '../assets/icons/aboutUs/cctv.png';
import access from '../assets/icons/aboutUs/access.png';
import intrusion from '../assets/icons/aboutUs/intrusionn.png';
import intercom from '../assets/icons/aboutUs/intercom.png';
import vehicle from '../assets/icons/aboutUs/vehicle_tracking.png';
import time from '../assets/icons/services/time.png';
import fire from '../assets/icons/services/fire.png';
import gate from '../assets/icons/services/gate.png';
import audio from '../assets/icons/aboutUs/audio.png';
import infrastructure from '../assets/icons/aboutUs/infrastructure.png';
import cabling from '../assets/icons/aboutUs/cabling.png';
import fence from '../assets/icons/services/fence.png';

//action imports
import { clearSubService } from "../actions/NavActions";

class Tools extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            servicesPageContent: this.props.servicesPageContent,
            subService: this.props.subService,
        };

        this.renderParagraph = this.renderParagraph.bind(this);
    }

    componentDidMount() {
        const section = this.state.subService;

        if (section) {
            scrollToComponent(this[section]);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.servicesPageContent !== this.props.servicesPageContent) {
            this.setState({ servicesPageContent: nextProps.servicesPageContent })
        }

        if (nextProps.subService !== this.props.subService) {
            this.setState({ subService: nextProps.subService });

            const section = this.state.subService;

            scrollToComponent(this[section]);
        }
    }

    componentWillUnmount() {
        this.props.clearSubService();
    }

    renderParagraph(section) {
        const contents = this.state.servicesPageContent;
        //fetch the string
        let paragraph = contents[section].value;

        return <div dangerouslySetInnerHTML={{__html: paragraph}}></div>
    }

    render() {

        const contents = this.state.servicesPageContent;

        return(
            <div className={"tools"}>
                <section className={"cctvSurveillanceSystems box"} ref={(section) => { this.cctvSurveillanceSystems = section; }}>
                    <div className={"imageBox"}>
                        <img src={cctv} alt={'slideShowImage'} />
                    </div>

                    <div style={{ textAlign: 'justify'}}>
                        <h1>CCTV SURVEILLANCE SYSTEM</h1>
                        <div>{ contents.cctvSurveillanceSystems ? this.renderParagraph('cctvSurveillanceSystems') : 'Loading...'}</div>
                    </div>
                </section>

                <section className={"electricFence box"} ref={(section) => { this.electricFence = section; }}>
                    <div className={"imageBox"}>
                        <img src={fence} alt={'slideShowImage'} />
                    </div>

                    <div style={{ textAlign: 'justify'}}>
                        <h1>ELECTRIC FENCE</h1>
                        <div>{ contents.electricFence ? this.renderParagraph('electricFence') : 'Loading...'}</div>
                    </div>
                </section>

                <section className={"timeAttendance box"} ref={(section) => { this.timeAttendance = section; }}>
                    <div className={"imageBox"}>
                        <img src={time} alt={'slideShowImage'} />
                    </div>

                    <div style={{ textAlign: 'justify'}}>
                        <h1>TIME & ATTENDANCE SYSTEM</h1>
                        <div>{ contents.timeAttendance ? this.renderParagraph('timeAttendance') : 'Loading...'}</div>
                    </div>
                </section>

                <section className={"accessControlSystems box"} ref={(section) => { this.accessControlSystems = section; }}>
                    <div className={"imageBox"}>
                        <img src={access} alt={'slideShowImage'} />
                    </div>

                    <div style={{ textAlign: 'justify'}}>
                        <h1>ACCESS CONTROL SYSTEM</h1>
                        <div>{ contents.accessControlSystems ? this.renderParagraph('accessControlSystems') : 'Loading...'}</div>
                    </div>
                </section>

                <section className={"gateAutomation box"} ref={(section) => { this.gateAutomation = section; }}>
                    <div className={"imageBox"}>
                        <img src={gate} alt={'slideShowImage'} />
                    </div>

                    <div style={{ textAlign: 'justify'}}>
                        <h1>GATE AUTOMATION</h1>
                        <div>{ contents.gateAutomation ? this.renderParagraph('gateAutomation') : 'Loading...'}</div>
                    </div>
                </section>


                <section className={"intrusionAlarmSystems box"} ref={(section) => { this.intrusionAlarmSystems = section; }}>
                    <div className={"imageBox"}>
                        <img src={intrusion} alt={'slideShowImage'} />
                    </div>

                    <div style={{ textAlign: 'justify'}}>
                        <h1>INTRUSION ALARM SYSTEM</h1>
                        <div>{ contents.intrusionAlarmSystems ? this.renderParagraph('intrusionAlarmSystems') : 'Loading...'}</div>
                    </div>
                </section>

                <section className={"fireDetection box"} ref={(section) => { this.fireDetection = section; }}>
                    <div className={"imageBox"}>
                        <img src={fire} alt={'slideShowImage'} />
                    </div>

                    <div style={{ textAlign: 'justify'}}>
                        <h1>FIRE DETECTION / ALARM SYSTEM</h1>
                        <div>{ contents.fireDetection ? this.renderParagraph('fireDetection') : 'Loading...'}</div>
                    </div>
                </section>


                <section className={"audioVideoIntercomSystems box"} ref={(section) => { this.audioVideoIntercomSystems = section; }}>
                    <div className={"imageBox"}>
                        <img src={intercom} alt={'slideShowImage'} />
                    </div>

                    <div style={{ textAlign: 'justify'}}>
                        <h1>AUDIO / VIDEO INTERCOM SYSTEM</h1>
                        <div>{ contents.audioVideoIntercomSystems ? this.renderParagraph('audioVideoIntercomSystems') : 'Loading...'}</div>
                    </div>
                </section>


                <section className={"vehicleTrackingSystems box"} ref={(section) => { this.vehicleTrackingSystems = section; }}>
                    <div className={"imageBox"}>
                        <img src={vehicle} alt={'slideShowImage'} />
                    </div>

                    <div style={{ textAlign: 'justify'}}>
                        <h1>VEHICLE TRACKING SYSTEM</h1>
                        <div>{ contents.vehicleTrackingSystems ? this.renderParagraph('vehicleTrackingSystems') : 'Loading...'}</div>
                    </div>
                </section>


                <section className={"audioSystems box"} ref={(section) => { this.audioSystems = section; }}>
                    <div className={"imageBox"}>
                        <img src={audio} alt={'slideShowImage'} />
                    </div>

                    <div style={{ textAlign: 'justify'}}>
                        <h1>AUDIOVISUAL SYSTEM</h1>
                        <div>{ contents.audioSystems ? this.renderParagraph('audioSystems') : 'Loading...'}</div>
                    </div>
                </section>


                <section className={"itInfrastructureAndNetworking box"} ref={(section) => { this.itInfrastructureAndNetworking = section; }}>
                    <div className={"imageBox"}>
                        <img src={infrastructure} alt={'slideShowImage'} />
                    </div>

                    <div style={{ textAlign: 'justify'}}>
                        <h1>IT INFRASTRUCTURE AND NETWORKING</h1>
                        <div>{ contents.itInfrastructureAndNetworking ? this.renderParagraph('itInfrastructureAndNetworking') : 'Loading...'}</div>
                    </div>
                </section>


                <section className={"structuredCabling box"} ref={(section) => { this.structuredCabling = section; }}>
                    <div className={"imageBox"}>
                        <img src={cabling} alt={'slideShowImage'} />
                    </div>

                    <div style={{ textAlign: 'justify'}}>
                        <h1>STRUCTURED CABLING</h1>
                        <div>{ contents.structuredCabling ? this.renderParagraph('structuredCabling') : 'Loading...'}</div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { servicesPageContent } = state.panel;
    const { subService } = state.nav;

    return {
        servicesPageContent,
        subService
    }
};

export default connect(mapStateToProps, { clearSubService })(Tools);