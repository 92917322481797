import {CLEAR_SUB_SERVICE, SHOW_SUB_SERVICE} from "./Types";

export const showSubService = (subService) => {
    return {
        type: SHOW_SUB_SERVICE,
        payload: subService
    }
};

export const clearSubService = () => {
    return {
        type: CLEAR_SUB_SERVICE
    }
};