import React from 'react';
import { connect } from 'react-redux';
//import _ from 'lodash';

//file imports
import cctv from '../assets/icons/cctv_black.png';
import fence from '../assets/icons/fence_black.png';
import timer from '../assets/icons/timer_attendance_black.png';
import accessControl from '../assets/icons/fingerprint_scan_black.png';
import fire from '../assets/icons/fire_black.png';
import intrusion from '../assets/icons/siren_black.png';

class LandingPageBody extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            landingPageContent: this.props.landingPageContent,
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.landingPageContent !== this.props.landingPageContent) {
            this.setState({ landingPageContent: nextProps.landingPageContent })
        }
    }

    render() {

        // const contents = _.map(this.state.landingPageContent, content => {
        //     return content;
        // });

        const contents = this.state.landingPageContent;


        return(
            <div className={"landingPageBody"}>
                <div className={"bodyBox"}>
                    <img src={cctv} alt={'cctv camera'}/>
                    <h2 id={"whyChooseUsTitle"}>CCTV Camera</h2>
                    <p>{ contents.cctvCamera ? contents.cctvCamera.value : ''}</p>
                </div>

                <div className={"bodyBox"}>
                    <img src={fence} alt={'electric fence'}/>
                    <h2 id={"whyChooseUsTitle"}>Electric Fence</h2>
                    <p>{ contents.electricFence ? contents.electricFence.value : ''}</p>
                </div>

                <div className={"bodyBox"}>
                    <img src={timer} alt={'timer attendance'}/>
                    <h2 id={"whyChooseUsTitle"}>Time & Attendance System</h2>
                    <p>{ contents.timerAttendance ? contents.timerAttendance.value : ''}</p>
                </div>

                <div className={"bodyBox"}>
                    <img src={accessControl} alt={'access control'}/>
                    <h2 id={"whyChooseUsTitle"}>Access Control System</h2>
                    <p>{ contents.accessControl ? contents.accessControl.value : ''}</p>
                </div>

                <div className={"bodyBox"}>
                    <img src={fire} alt={'fire detection'}/>
                    <h2 id={"whyChooseUsTitle"}>Fire Detection/Alarm System</h2>
                    <p>{ contents.fireDetection ? contents.fireDetection.value : ''}</p>
                </div>

                <div className={"bodyBox"}>
                    <img src={intrusion} alt={'intrusion system'}/>
                    <h2 id={"whyChooseUsTitle"}>Intrusion System</h2>
                    <p>{ contents.intrusionSystem ? contents.intrusionSystem.value : ''}</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { landingPageContent } = state.panel;

    return {
        landingPageContent
    }
};

export default connect(mapStateToProps)(LandingPageBody);