import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

//css
import './index.css';

//store setup
import { Provider } from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import reducers from "./reducers/index";

//component imports
import App from './App';

//property declaration
const ReduxThunk = require('redux-thunk').default;
const store = createStore(reducers, applyMiddleware(ReduxThunk));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={store}>
          <App />
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
