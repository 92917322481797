import React from 'react';
import { connect } from 'react-redux';

//file imports
import vision from '../assets/icons/vision.png';
import mission from '../assets/icons/mission.png';
import coreValues from '../assets/icons/core_values.png';
import teamWork from '../assets/icons/teamwork.png';

class AboutUs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            aboutUsPageContent: this.props.aboutUsPageContent,
        };

        this.renderParagraph = this.renderParagraph.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.aboutUsPageContent !== this.props.aboutUsPageContent) {
            this.setState({ aboutUsPageContent: nextProps.aboutUsPageContent })
        }
    }

    // renderParagraph(section) {
    //     const contents = this.state.aboutUsPageContent;
    //     //fetch the string
    //     let paragraph = contents[section].value;
    //
    //     //find if there's a paragraph initializer
    //     if (paragraph.includes("<p>")) {
    //         //if paragraph initializer exists fetch the paragraph
    //         let paragraphStartsAt = paragraph.indexOf("<p>");
    //         let end = paragraph.lastIndexOf("</p>");
    //
    //         //add index of initializer symbol length
    //         const start = paragraphStartsAt + 3;
    //
    //         //fetch the new paragraph
    //         const newParagraph = paragraph.slice(start, end);
    //
    //         //cut the new paragraph from the original paragraph
    //         const originalParagraph = paragraph.slice(0, (start - 3));
    //
    //         return(
    //             <div>
    //                 <p>{originalParagraph}</p>
    //                 <p>{newParagraph}</p>
    //             </div>
    //         );
    //     } else {
    //         return <p>{paragraph}</p>
    //     }
    // }

    renderParagraph(section) {
        const contents = this.state.aboutUsPageContent;
        //fetch the string
        let paragraph = contents[section].value;

        return <div dangerouslySetInnerHTML={{__html: paragraph}}></div>
    }

    render() {

        const contents = this.state.aboutUsPageContent;


        return(
            <div className={"aboutUs"}>
                <h1>About Us</h1>
                <div style={{ textAlign: 'justify', paddingBottom: '4rem', paddingTop: '1rem'}}>
                    <div>{ contents.aboutUs ? this.renderParagraph('aboutUs') : 'Loading...'}</div>
                </div>


                <div className={"aboutUsGrid"}>
                    <div className={"vision box"}>
                        <div className={"imageBox"}>
                            <img src={vision} alt={'slideShowImage'} />
                        </div>

                        <div style={{ textAlign: 'justify', paddingBottom: '4rem'}}>
                            <h1>Vision</h1>
                            <div>{ contents.vision ? this.renderParagraph('vision')  : 'Loading...'}</div>
                        </div>
                    </div>

                    <div className={"mission box"}>
                        <div className={"imageBox"}>
                            <img src={mission} alt={'slideShowImage'} />
                        </div>

                        <div style={{ textAlign: 'justify', paddingBottom: '4rem'}}>
                            <h1>Mission</h1>
                            <div>{ contents.mission ? this.renderParagraph('mission')  : 'Loading...'}</div>
                        </div>

                    </div>

                    <div className={"coreValues box"}>
                        <div className={"imageBox"}>
                            <img src={coreValues} alt={'slideShowImage'} />
                        </div>

                        <div style={{ textAlign: 'justify', paddingBottom: '4rem'}}>
                            <h1>Core Values</h1>
                            <div>{ contents.coreValues ? this.renderParagraph('coreValues')  : 'Loading...'}</div>
                        </div>

                    </div>

                    <div className={"team box"}>
                        <div className={"imageBox"}>
                            <img src={teamWork} alt={'slideShowImage'} />
                        </div>

                        <div style={{ textAlign: 'justify', paddingBottom: '4rem'}}>
                            <h1>Our Team</h1>
                            <div>{ contents.ourTeam ? this.renderParagraph('ourTeam')  : 'Loading...'}</div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { aboutUsPageContent } = state.panel;

    return {
        aboutUsPageContent
    }
};

export default connect(mapStateToProps)(AboutUs);