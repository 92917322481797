import React from 'react';
import { connect } from 'react-redux';

//file imports
import experience from '../assets/icons/stonehenge.png';
import quality from '../assets/icons/quality_color.png';
import support from '../assets/icons/support_color.png';
// import postPurchase from '../assets/icons/post_service_support.png';

class WhyChooseUs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            landingPageContent: this.props.landingPageContent,
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.landingPageContent !== this.props.landingPageContent) {
            this.setState({ landingPageContent: nextProps.landingPageContent })
        }
    }

    render() {

        const contents = this.state.landingPageContent;

        return (
            <div className={"whyChooseUs"} >
                <h1>Why Choose Us</h1>
                <div className={"description"}>
                    <div className={"descriptionBox"}>
                        <div className={"imageBox"}>
                            <img src={experience} alt={'slideShowImage'} />
                        </div>
                        <div className={"info"}>
                            <h2 className={"info"}>EXPERIENCE</h2>
                            <p>{ contents.experience ? contents.experience.value : 'Loading...'}</p>
                        </div>
                    </div>

                    <div className={"descriptionBox"}>
                        <div className={"imageBox"}>
                            <img src={quality} alt={'slideShowImage'} />
                        </div>
                        <div className={"info"}>
                            <h2 className={"info"}>QUALITY</h2>
                            <p>{ contents.quality ? contents.quality.value : 'Loading...'}</p>
                        </div>
                    </div>

                    <div className={"descriptionBox"}>
                        <div className={"imageBox"}>
                            <img src={support} alt={'slideShowImage'} />
                        </div>
                        <div className={"info"}>
                            <h2 className={"info"}>AFFORDABILITY</h2>
                            <p>{ contents.affordability ? contents.affordability.value : 'Loading...'}</p>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { landingPageContent } = state.panel;

    return {
        landingPageContent
    }
};

export default connect(mapStateToProps)(WhyChooseUs);