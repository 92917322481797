import React from 'react';
import Carousel from 'react-multi-carousel';
import { connect } from 'react-redux';
import _ from 'lodash';

//css imports
import 'react-multi-carousel/lib/styles.css';

//action imports
import { fetchClientsImages } from "../actions/PanelActions";

//component imports
import { Spinner } from "./common/Index";

//file imports
// import hester from '../assets/gallery/clients/HesterWideLogo.png';
// import bq from '../assets/gallery/clients/BQWide.png';
// import ternet from '../assets/gallery/clients/TernetWide.png';

class HappyClients extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            album: this.props.currentClientsPictures,
        }
    }
    
    componentWillMount() {
        this.props.fetchClientsImages();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.currentClientsPictures !== this.props.currentClientsPictures) {
            this.setState({ album: nextProps.currentClientsPictures })
        }
    }
    
    render() {

        const album = _.map(this.state.album, imageObj => {
            return {
                src: imageObj.downloadURL,
                thumbnail: imageObj.downloadURL,
                thumbnailWidth: 320,
                thumbnailHeight: 174,
                fileName: imageObj.fileName,
                imageID: imageObj.imageID,
            };

        });

        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 5,
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3,
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 3,
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 2,
            },
        };

        return(
            <div className={"happyClients"}>
                <h1>Our Happy Clients</h1>
                <div className={"clients"}>

                    <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={4000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["desktop","tablet", "mobile"]}
                        deviceType={this.props.deviceType}
                        // centerMode={true}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                    >
                        {
                            album ?
                                album.map(image => {
                                    return(
                                        <div key={image.imageID}>
                                            <img src={image.src} alt={'slideShowImage'} />
                                        </div>
                                    );
                                }):
                                <div>
                                    <Spinner/>
                                </div>
                        }
                    </Carousel>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { currentClientsPictures } = state.panel;

    return {
        currentClientsPictures,
    }
};

export default connect(mapStateToProps, { fetchClientsImages })(HappyClients);