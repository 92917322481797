import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";

//action imports
import { deleteMessage } from "../actions/ContactActions";

//component imports
import {Button} from "./common/Index";

class ContactUsPanel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            messages: this.props.messages
        };

        this.onDeletePress = this.onDeletePress.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.messages !== this.props.messages) {
            this.setState({ messages: nextProps.messages })
        }
    }

    onDeletePress(message) {
        this.props.deleteMessage(message);
    }

    render() {

        const newMessages = _.map(this.state.messages, message => {
            return message
        });

        return(
            <div className={"contactUsPanel panel"}>
                <h2>Inbox</h2>

                <div>
                    {
                        newMessages.map(message => {
                            return (
                                <div className={"message"}>
                                    <div>
                                        <div className={"messageTitle"}>
                                            <h3>{message.name}</h3>
                                            <h3>{message.email}</h3>
                                        </div>
                                        <p>{message.request}</p>
                                    </div>
                                    <div className={"buttonContainer"}>
                                        <Button
                                            text={"Delete"}
                                            onPress={() => this.onDeletePress(message)}
                                        />
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {

    const { messages } = state.contact;

    return {
        messages
    }

};

export default connect(mapStateToProps, { deleteMessage })(ContactUsPanel);