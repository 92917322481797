import React from 'react';
import { connect } from 'react-redux';
import { NavLink, Route, BrowserRouter } from "react-router-dom";
import {
    BrowserView,
    MobileView,
    // isBrowser,
    isMobile
} from "react-device-detect";
import { Menu, Dropdown, Icon } from 'antd';
import { bubble as SideMenu } from 'react-burger-menu';
import moment from "moment";

//action imports
import { fetchLandingImages, fetchLandingPageContent, fetchOurPartnersImages, fetchClientsImages, fetchAboutUsPageContent,
    fetchServicesPageContent, fetchGalleryImages, } from "../actions/PanelActions";
import { showSubService } from "../actions/NavActions";
import { retrieveMessages } from "../actions/ContactActions";

//component imports
import LandingPage from './LandingPage';
import AboutUs from './AboutUs';
import Services from './Services';
import Gallery from './Gallery';
import Contact from './Contact';
import AdminLogin from './AdminLogin';
import AdminPanel from './AdminPanel';
import AccessControl from './subServices/AccessControl';
import AudioVideo from './subServices/AudioVideo';
import AudioVisual from './subServices/AudioVisual';
import CctvCamera from './subServices/CctvCamera';
import ElectricFence from './subServices/ElectricFence';
import FireDetection from './subServices/FireDetection';
import GateAutomation from './subServices/GateAutomation';
import IntrusionAlarm from './subServices/IntrusionAlarm';
import ItInfrastructure from './subServices/ItInfrastructure';
import StructuredCabling from './subServices/StructuredCabling';
import TimeAttendance from './subServices/TimeAttendance';
import VehicleTracking from './subServices/VehicleTracking';

//file imports
import logo from '../assets/logo.png';
//import logoI from '../assets/logoII.png';
import logo_yellow from '../assets/logo_yellow.png';
import logo_yellow_tagline from '../assets/logo_yellow_tagline_flat.png';

//social media icons imports
import facebookLogo from "../assets/social_icons/facebook.png";
//import twitterLogo from "../assets/social_icons/twitter.png";
import instagramLogo from "../assets/social_icons/instagram.png";
//import youtubeLogo from "../assets/social_icons/youtube.png";

//css imports
import 'antd/es/dropdown/style/css';

//properties declaration


class Main extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            logo: logo_yellow_tagline,
            logoStyle: {
                width: '13rem',
                height: '6.5rem',
                marginLeft: '2rem'
            },
            menuOpen: false,

        };

        this.renderMobileLogo = this.renderMobileLogo.bind(this);
        this.resizeHeaderOnScroll = this.resizeHeaderOnScroll.bind(this);

        this.handleStateChange = this.handleStateChange.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    componentWillMount() {
        this.props.fetchLandingImages();
        this.props.fetchLandingPageContent();
        this.props.fetchOurPartnersImages();
        this.props.fetchClientsImages();
        this.props.fetchAboutUsPageContent();
        this.props.fetchServicesPageContent();
        this.props.fetchGalleryImages();
        this.props.retrieveMessages();
    }

    componentDidMount() {
        window.addEventListener("scroll", this.resizeHeaderOnScroll);
    }

    resizeHeaderOnScroll() {
        const distanceY = window.pageYOffset || document.documentElement.scrollTop,
            shrinkOn = 20,
            headerEl = document.getElementById("dashboardNav");

        // if (distanceY > shrinkOn) {
        //     //if user scrolls down
        //     headerEl.classList.add("smaller");
        //
        //     this.setState({ logo: logo_yellow, logoStyle: { width: '8rem', height: '8rem'}})
        // } else {
        //     //if user is at top of page
        //     headerEl.classList.remove("smaller");
        //     this.setState({ logo: logo_yellow_tagline, logoStyle: { width: '16rem', height: '8rem', }})
        // }
    }

    //handle state change for the mobile side menu
    handleStateChange (state) {
        this.setState({menuOpen: state.isOpen})
    }

    //close the mobile side menu
    closeMenu () {
        this.setState({menuOpen: false})
    }

    // toggleMenu () {
    //     this.setState(state => ({menuOpen: !state.menuOpen}))
    // }


    renderMobileLogo () {
        if (isMobile) {
            return (
                <NavLink to={'/'}>
                    <img src={logo_yellow} style={{ width: '4rem', height: '4rem', zIndex: '1000'}} alt={'logo'} />
                </NavLink>
            );
        } else {
            return (
                <NavLink to={'/'}>
                    <img src={this.state.logo} style={this.state.logoStyle} alt={'logo'} />
                </NavLink>
            );
        }
    }

    render() {

        const now = moment();
        const year = now.format("YYYY");
        const menu = (
            <Menu>
                <Menu.Item>
                    <NavLink to={'/cctv-camera'} >
                        CCTV Surveillance
                    </NavLink>
                </Menu.Item>
                <Menu.Item>
                    <NavLink to={'/electric-fence'} >
                        Electric Fence
                    </NavLink>
                </Menu.Item>
                <Menu.Item>
                    <NavLink to={'/time-attendance'} >
                        Time & Attendance System
                    </NavLink>
                </Menu.Item>
                <Menu.Item>
                    <NavLink to={'/access-control'} >
                        Access Control System
                    </NavLink>
                </Menu.Item>
                <Menu.Item>
                    <NavLink to={'/fire-detection'} >
                        Fire Detection/Alarm System
                    </NavLink>
                </Menu.Item>
                <Menu.Item>
                    <NavLink to={'/intrusion-alarm'} >
                        Intrusion Alarms
                    </NavLink>
                </Menu.Item>
                <Menu.Item>
                    <NavLink to={'/audio-video'} >
                        Audio/Video Intercom
                    </NavLink>
                </Menu.Item>
                <Menu.Item>
                    <NavLink to={'/vehicle-tracking'} >
                        Vehicle Tracking
                    </NavLink>
                </Menu.Item>
                <Menu.Item>
                    <NavLink to={'/gate-automation'} >
                        Gate Automation System
                    </NavLink>
                </Menu.Item>
                <Menu.Item>
                    <NavLink to={'/audio-visual'} >
                        Audiovisual System
                    </NavLink>
                </Menu.Item>
                <Menu.Item>
                    <NavLink to={'/it-infrastructure'} >
                        IT Infrastructure & Networking
                    </NavLink>
                </Menu.Item>
                <Menu.Item>
                    <NavLink to={'/structured-cabling'} >
                        Structured Cabling
                    </NavLink>
                </Menu.Item>
            </Menu>
        );

        return(
            <BrowserRouter>
                <div>
                    <div className={"dashboardNav"} id={"dashboardNav"}>
                        <div className={'dashboardNavLogo'}>
                            { this.renderMobileLogo() }
                        </div>

                        <div className={'dashboardNavMenu'}>

                            <BrowserView>
                                <ul>
                                    <NavLink to={'/about-us'}><li>About Us</li></NavLink>
                                    <NavLink to={'/services'}>
                                        <li>
                                            <Dropdown overlay={menu}>
                                                <a className="ant-dropdown-link" href="#">
                                                    Our Services <Icon type="down" />
                                                </a>
                                            </Dropdown>
                                        </li>
                                    </NavLink>
                                    <NavLink to={'/gallery'}><li>Gallery</li></NavLink>
                                    <NavLink to={'/contact'}><li>Contact</li></NavLink>
                                </ul>
                            </BrowserView>

                            <MobileView>
                                <div className={"mobileMenu"}>
                                    <SideMenu
                                        right
                                        width={ '50%' }
                                        isOpen={this.state.menuOpen}
                                        onStateChange={(state) => this.handleStateChange(state)}
                                    >
                                        <NavLink to={'/'} onClick={() => this.closeMenu()}><p>Home</p></NavLink>
                                        <NavLink to={'/about-us'} onClick={() => this.closeMenu()}><p>About Us</p></NavLink>
                                        <NavLink to={'/services'} onClick={() => this.closeMenu()}>
                                            <p>
                                                <Dropdown overlay={menu}>
                                                    <a className="ant-dropdown-link" href="#">
                                                        Our Services <Icon type="down" />
                                                    </a>
                                                </Dropdown>
                                            </p>
                                        </NavLink>
                                        <NavLink to={'/gallery'} onClick={() => this.closeMenu()}><p>Gallery</p></NavLink>
                                        <NavLink to={'/contact'} onClick={() => this.closeMenu()}><p>Contact</p></NavLink>
                                    </SideMenu>
                                </div>
                            </MobileView>

                            <div className={"socialMediaContainer"}>
                                <a href={"https://www.facebook.com/monarchy_technology-2464171400313453/"} target={"_blank"} id={"socialLinks"} rel={"noreferrer"}><img src={facebookLogo} className={"socialLogo"} alt="facebook logo" /></a>
                                <a href={"https://www.instagram.com/monarchy_technology/"} target={"_blank"} id={"socialLinks"} rel={"noreferrer"}><img src={instagramLogo} className={"socialLogo"} alt=" instagram logo" /></a>
                                {/*<a href={"https://twitter.com/"} target={"_blank"} id={"socialLinks"} rel={"noreferrer"}><img src={twitterLogo} className={"socialLogo"} alt="twitter logo" /></a>*/}
                                {/*<a href={"https://www.youtube.com/"} target={"_blank"} id={"socialLinks"} rel={"noreferrer"}><img src={youtubeLogo} className={"socialLogo"} alt="youtube logo" /></a>*/}
                            </div>
                        </div>
                    </div>

                    <div className={"dashboardContent"}>
                        <Route exact path={'/'} component={ LandingPage }/>
                        <Route exact path={'/about-us'} component={ AboutUs }/>
                        <Route exact path={'/services'} component={ Services }/>
                        <Route exact path={'/gallery'} component={ Gallery }/>
                        <Route exact path={'/contact'} component={ Contact }/>
                        <Route exact path={'/admin-login'} component={ AdminLogin }/>
                        <Route exact path={'/admin-panel'} component={ AdminPanel }/>

                        <Route exact path={'/access-control'} component={AccessControl }/>
                        <Route exact path={'/audio-video'} component={ AudioVideo }/>
                        <Route exact path={'/audio-visual'} component={ AudioVisual }/>
                        <Route exact path={'/cctv-camera'} component={ CctvCamera }/>
                        <Route exact path={'/electric-fence'} component={ ElectricFence }/>
                        <Route exact path={'/fire-detection'} component={ FireDetection }/>
                        <Route exact path={'/gate-automation'} component={ GateAutomation }/>
                        <Route exact path={'/intrusion-alarm'} component={ IntrusionAlarm }/>
                        <Route exact path={'/it-infrastructure'} component={ ItInfrastructure }/>
                        <Route exact path={'/structured-cabling'} component={ StructuredCabling }/>
                        <Route exact path={'/time-attendance'} component={ TimeAttendance }/>
                        <Route exact path={'/vehicle-tracking'} component={ VehicleTracking }/>
                    </div>

                    <footer>
                        <p>©<span>{year}</span> Monarchy Technology Company Limited</p>
                        <p>P.O. Box 76079 Dar es Salaam, Mikocheni B, Manyara/Mazinde St. | Tanzania</p>
                        <p>+255 754 731 088 | +255 22 278 0494</p>
                        { isMobile ? null : <p><NavLink to={'/admin-login'}>Admin</NavLink></p> }
                    </footer>
                </div>
            </BrowserRouter>
        );
    }
}

export default connect(null, { fetchLandingImages, fetchLandingPageContent, fetchOurPartnersImages, fetchClientsImages,
    fetchAboutUsPageContent, fetchServicesPageContent, showSubService,
    retrieveMessages, fetchGalleryImages })(Main);