import React from 'react';
import { ClimbingBoxLoader } from 'react-spinners';

export const Spinner = () => {
    return (
        <div className={'spinnerStyle'}>
            <ClimbingBoxLoader
                color={'#123647'}
                loading={ true }

            />
        </div>
    );
};