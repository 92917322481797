import {
    FETCH_LANDING_IMAGES_SUCCESS,
    LANDING_IMAGE_SELECTED,
    FETCH_GALLERY_IMAGES_SUCCESS,
    GALLERY_IMAGE_SELECTED,
    SERVICES_OFFERED_RADIO_VALUE_CHANGED,
    SERVICE_OFFERED_DESCRIPTION_UPDATE,
    CLEAR_DESCRIPTION_BOX,
    FETCH_LANDING_PAGE_CONTENT_SUCCESSFUL,
    WHY_CHOOSE_US_RADIO_VALUE_CHANGED,
    WHY_CHOOSE_US_DESCRIPTION_UPDATE,
    ABOUT_US_RADIO_VALUE_CHANGED,
    ABOUT_US_DESCRIPTION_UPDATE,
    FETCH_ABOUT_US_PAGE_CONTENT_SUCCESSFUL,
    FETCH_SERVICES_PAGE_CONTENT_SUCCESFUL,
    SERVICES_DESCRIPTION_UPDATE,
    SERVICES_RADIO_VALUE_CHANGED,
    DESCRIPTION_SAVED_SUCCESSFULLY,
    FETCH_OUR_PARTNERS_IMAGES_SUCCESS,
    OUR_PARTNER_IMAGE_SELECTED,
    FETCH_CLIENTS_IMAGES_SUCCESS, CLIENT_IMAGE_SELECTED,
} from "../actions/Types";

const INITIAL_STATE = {
    currentLandingPictures: [],
    selectedLandingImages: [],
    currentGalleryPictures: [],
    selectedGalleryImages: [],
    currentOurPartnersPictures: [],
    selectedOurPartnersImages: [],
    currentClientsPictures: [],
    selectedClientsImages: [],
    servicesOfferedRadioValue: 'cctvCamera',
    servicesOfferedDescription: '',
    whyChooseUsRadioValue: 'experience',
    whyChooseUsDescription: '',
    aboutUsRadioValue: 'aboutUs',
    aboutUsDescription: '',
    servicesRadioValue: 'cctvSurveillanceSystems',
    servicesDescription: '',
    landingPageContent: {},
    aboutUsPageContent: {},
    servicesPageContent: {},
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {


        //page content fetch reducers
        case FETCH_SERVICES_PAGE_CONTENT_SUCCESFUL:
            console.log("services page content");
            console.log(action.payload);
            return { ...state, servicesPageContent: action.payload };
        case FETCH_ABOUT_US_PAGE_CONTENT_SUCCESSFUL:
            console.log("about us page content");
            console.log(action.payload);
            return { ...state, aboutUsPageContent: action.payload };
        case FETCH_LANDING_PAGE_CONTENT_SUCCESSFUL:
            return { ...state, landingPageContent: action.payload };


        //description save success reducers
        case DESCRIPTION_SAVED_SUCCESSFULLY:
            return { ...state, servicesOfferedDescription: '', whyChooseUsDescription: '', aboutUsDescription: '', servicesDescription: ''};
        case CLEAR_DESCRIPTION_BOX:
            return { ...state, cctvCamera: '', electricFence: '', timerAttendance: '', accessControl: '',
                fireDetection: '', intrusionSystem: '', };


        //description update reducers
        case SERVICES_DESCRIPTION_UPDATE:
            return { ...state, servicesDescription: action.payload };
        case ABOUT_US_DESCRIPTION_UPDATE:
            return { ...state, aboutUsDescription: action.payload };
        case WHY_CHOOSE_US_DESCRIPTION_UPDATE:
            return { ...state, whyChooseUsDescription: action.payload };
        case SERVICE_OFFERED_DESCRIPTION_UPDATE:
            return { ...state, servicesOfferedDescription: action.payload };


        //radio changed reducers
        case SERVICES_RADIO_VALUE_CHANGED:
            return { ...state, servicesRadioValue: action.payload };
        case ABOUT_US_RADIO_VALUE_CHANGED:
            return { ...state, aboutUsRadioValue: action.payload };
        case WHY_CHOOSE_US_RADIO_VALUE_CHANGED:
            return { ...state, whyChooseUsRadioValue: action.payload };
        case SERVICES_OFFERED_RADIO_VALUE_CHANGED:
            return { ...state, servicesOfferedRadioValue: action.payload };


        //selected reducers
        case CLIENT_IMAGE_SELECTED:
            return { ...state, selectedClientsImages: action.payload };
        case OUR_PARTNER_IMAGE_SELECTED:
            return { ...state, selectedOurPartnersImages: action.payload };
        case GALLERY_IMAGE_SELECTED:
            return { ...state, selectedGalleryImages: action.payload };
        case LANDING_IMAGE_SELECTED:
            return { ...state, selectedLandingImages: action.payload };


        //fetch reducers
        case FETCH_CLIENTS_IMAGES_SUCCESS:
            return { ...state, currentClientsPictures: action.payload };
        case FETCH_OUR_PARTNERS_IMAGES_SUCCESS:
            return { ...state, currentOurPartnersPictures: action.payload };
        case FETCH_GALLERY_IMAGES_SUCCESS:
            return { ...state, currentGalleryPictures: action.payload };
        case FETCH_LANDING_IMAGES_SUCCESS:
            return { ...state, currentLandingPictures: action.payload };
        default:
            return state
    }
}