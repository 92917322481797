import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import firebase from 'firebase';
// import logo from './logo.svg';
import {
  isMobile
} from "react-device-detect";
import Countdown from 'react-countdown-now';

//component imports
import Main from './components/Main';

//file imports
import logo from './assets/logo.png';

//css imports
import './App.css';
import './responsiveApp.css';


class App extends React.Component {
  constructor(props) {
    super(props);

    this.renderComponents = this.renderComponents.bind(this);
  }

  componentWillMount(){
    const config = {
      apiKey: "AIzaSyCprNbqCtAMQHBBk9LgzkLZSxYyg7dVOOc",
      authDomain: "monarchy-23.firebaseapp.com",
      databaseURL: "https://monarchy-23.firebaseio.com",
      projectId: "monarchy-23",
      storageBucket: "monarchy-23.appspot.com",
      messagingSenderId: "747516119185",
      appId: "1:747516119185:web:999c94f93bdf0710451730"
    };

    firebase.initializeApp(config);
    const firestore = firebase.firestore();

    //time stamp setting
    const settings = {/* your settings... */ timestampsInSnapshots: true};
    firestore.settings(settings);

  }

  renderComponents() {
    if (!isMobile) {
      return (
          <div className={"mobile"}>
            <div className={"mobileLogo"}>
              <img src={logo} style={{ width: '6rem', height: '6rem'}} alt={'logo'} />
            </div>

            <div className={"countDown"}>
              <Countdown date={Date.now() + 200000000} />
            </div>
          </div>
      );
    } else {
      return <Main/>
    }
  }

  render() {
    return (
        <BrowserRouter>
          <div className="App">
            <Main/>
          </div>
        </BrowserRouter>
    );
  }
}

export default App;