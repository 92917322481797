import firebase from 'firebase';

import {
    CONTACT_EMAIL_UPDATED,
    CONTACT_NAME_UPDATED,
    CONTACT_REQUEST_SENT_SUCCESSFULLY,
    CONTACT_REQUEST_UPDATED, FETCH_MESSAGES_SUCCESSFUL
} from "./Types";

export const contactRequestUpdated = ({ prop, value}) => {

    switch (prop) {
        case "contactName":
            return {
                type: CONTACT_NAME_UPDATED,
                payload: value
            };
        case "contactEmail":
            return {
                type: CONTACT_EMAIL_UPDATED,
                payload: value
            };
        case "contactRequest":
            return {
                type: CONTACT_REQUEST_UPDATED,
                payload: value
            };
        default:
            break;
    }

};

export const sendMessage = ({ name, email, request }) => {

    const ID = Math.random().toString(36).substring(6).toUpperCase();

    return(dispatch) => {

        firebase.firestore().collection('users').doc("admins").collection("UbmU3WjVolO3j3x8gn967fQpQbZ2").doc("public").collection("contactPage").doc("messages")
            .set({ [ID]: { name, email, request, ID }}, { merge: true })
            .then(() => {

                console.log("message sent successfully");

                dispatch({
                    type: CONTACT_REQUEST_SENT_SUCCESSFULLY
                })
            })
            .catch(err => {
                console.log(err);
                console.log("message failed to send")
            });

    }
};

export const retrieveMessages = () => {

    return(dispatch) => {
        firebase.firestore().collection('users').doc("admins").collection("UbmU3WjVolO3j3x8gn967fQpQbZ2").doc("public").collection("contactPage").doc("messages")
            .onSnapshot((doc) => {
                if (doc.exists) {
                    //manipulate doc
                    const data = doc.data();

                    dispatch({
                        type: FETCH_MESSAGES_SUCCESSFUL,
                        payload: data
                    })

                } else {
                    console.log("messages doc does'nt exist");
                }
            })
    };

};

export const deleteMessage = (message) => {
    const ID = message.ID;

    return() => {
        //delete video details from database
        firebase.firestore().collection('users').doc("admins").collection("UbmU3WjVolO3j3x8gn967fQpQbZ2").doc("public").collection("contactPage").doc("messages")
            .update({ [ID]: firebase.firestore.FieldValue.delete() })
            .then(() => {
                console.log("message deleted successfully from database");
            }).catch((error) => {
            console.log("message failed to delete from database");
            console.log(error);
        })
    }
};