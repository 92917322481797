//  admin types
export const CREDENTIAL_UPDATE = "credential_update";
export const LOGIN_USER = "login_user";
export const LOGIN_USER_SUCCESS = "login_user_success";
export const LOGIN_USER_FAIL = "login_user_fail";
export const SIGNUP_USER_FAIL = "signup_user_fail";
export const LOGOUT_ADMIN = "logout_admin";

//home panel types
//export const FETCH_LANDING_IMAGES = "fetch_landing_images";
export const FETCH_LANDING_IMAGES_SUCCESS = "fetch_landing_images_success";
export const FETCH_LANDING_IMAGES_FAILED = "fetch_landing_images_failed";
export const LANDING_IMAGE_SELECTED = "landing_image_selected";

//export const FETCH_GALLERY_IMAGES = "fetch_gallery_images";
export const FETCH_GALLERY_IMAGES_SUCCESS = "fetch_gallery_images_success";
export const FETCH_GALLERY_IMAGES_FAILED = "fetch_gallery_images_failed";
export const GALLERY_IMAGE_SELECTED = "gallery_image_selected";

export const FETCH_OUR_PARTNERS_IMAGES_SUCCESS = "fetch_our_partners_images_success";
export const FETCH_OUR_PARTNERS_IMAGES_FAILED = "fetch_our_partners_images_failed";
export const OUR_PARTNER_IMAGE_SELECTED = "our_partner_image_selected";

export const FETCH_CLIENTS_IMAGES_SUCCESS = "fetch_clients_images_success";
export const FETCH_CLIENTS_IMAGES_FAILED = "fetch_clients_images_failed";
export const CLIENT_IMAGE_SELECTED = "client_image_selected";

export const FETCH_LANDING_PAGE_CONTENT_SUCCESSFUL = "fetch_landing_page_content_successful";
export const FETCH_ABOUT_US_PAGE_CONTENT_SUCCESSFUL = "fetch_about_us_page_content_successful";
export const FETCH_SERVICES_PAGE_CONTENT_SUCCESFUL = "fetch_services_page_content_successful";



//description types
export const SERVICES_OFFERED_RADIO_VALUE_CHANGED = "services_offered_radio_value_changed";
export const WHY_CHOOSE_US_RADIO_VALUE_CHANGED = "why_choose_us_radio_value_changed";
export const ABOUT_US_RADIO_VALUE_CHANGED = "about_us_radio_value_changed";
export const SERVICES_RADIO_VALUE_CHANGED = "services_radio_value_changed";
export const SERVICE_OFFERED_DESCRIPTION_UPDATE = "description_update";
export const WHY_CHOOSE_US_DESCRIPTION_UPDATE = "why_choose_us_description_update";
export const ABOUT_US_DESCRIPTION_UPDATE = "about_us_description_update";
export const SERVICES_DESCRIPTION_UPDATE = "services_description_update";
export const CLEAR_DESCRIPTION_BOX = "clear_description_box";

export const DESCRIPTION_SAVED_SUCCESSFULLY = "description_saved_successfully";

//nav types
export const SHOW_SUB_SERVICE = "show_sub_service";
export const CLEAR_SUB_SERVICE = "clear_sub_service";

//contact types
export const CONTACT_NAME_UPDATED = "contact_name_updated";
export const CONTACT_EMAIL_UPDATED = "contact_email_updated";
export const CONTACT_REQUEST_UPDATED = "contact_request_updated";
export const CONTACT_REQUEST_SENT_SUCCESSFULLY = "contact_request_sent_successfully";
export const CONTACT_REQUEST_FAILED = "contact_request_failed";
export const FETCH_MESSAGES_SUCCESSFUL = "fetch_messages-successful";