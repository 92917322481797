import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {

    isMobile
} from "react-device-detect";

//component imports
import { Spinner } from "./common/Index";

//  action imports
import { credentialUpdate, loginUser } from "../actions/AdminActions";
// import { hideMobileNav } from "../actions/AppActions";

//  component imports
import { EmailInput } from "./common/EmailInput";
import { PasswordInput } from "./common/PasswordInput";
import { Button } from "./common/Index";

class AdminLogin extends React.Component {

    constructor(props) {
        super(props);

        this.renderButton = this.renderButton.bind(this);
        this.onLoginPress = this.onLoginPress.bind(this);
        this.renderComponent = this.renderComponent.bind(this);
    }

    componentWillMount() {
        // this.props.hideMobileNav();
    }

    renderComponent() {
        if (isMobile) {
            return <div className={"mobileUnavailable"}><h3>This content is unavailable on mobile</h3></div>
        } else {
            return (
                <div>
                    <div className={"adminHeader"}>
                        <h2>ADMIN LOG IN</h2>
                    </div>

                    <div>
                        <hr className="style11" />
                    </div>

                    <div className={"adminLoginContainer"}>
                        <EmailInput
                            placeholder={"champagne@firm23.co.tz"}
                            value={this.props.email}
                            onChange={e => this.props.credentialUpdate({ prop: 'email', value: e.target.value })}
                        />

                        <PasswordInput
                            placeholder={"******"}
                            value={this.props.password}
                            onChange={e => this.props.credentialUpdate({ prop: 'password', value: e.target.value })}
                        />

                        {
                            this.props.error ?
                                <div className={"adminPageErrorContainer"}>
                                    <p>{this.props.error}</p>
                                </div> :
                                null
                        }

                        <div className={"adminBtnContainer"}>
                            { this.renderButton() }
                        </div>
                    </div>
                </div>
            );
        }
    }

    onLoginPress() {
        console.log("login pressed");
        const { email, password, history } = this.props;

        if (email !== "" && password !== "") {
            this.props.loginUser({ email, password, history });
        } else {
            alert("Please fill in required fields");
        }

    }

    renderButton() {
        if (this.props.loading) {
            return (
                <Spinner size={'small'}/>
            );
        }
        else {
            return (
                <Button
                    text={'Login'}
                    onPress={ () => this.onLoginPress() }
                />
            );
        }
    }


    render() {
        return (
            <div className={"adminLoginPage"}>

                <div className={"adminLoginContainer"}>
                    { this.renderComponent() }
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { email, password, error, loading } = state.admin;

    return {
        email,
        password,
        error,
        loading
    }
};

export default withRouter(connect(mapStateToProps, { credentialUpdate, loginUser })(AdminLogin));