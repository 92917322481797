import React from 'react';
import { connect } from 'react-redux';

//file imports
import cabling from '../../assets/icons/aboutUs/cabling.png';

class StructuredCabling extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            servicesPageContent: this.props.servicesPageContent,
        };

        this.renderParagraph = this.renderParagraph.bind(this);
    }


    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.servicesPageContent !== this.props.servicesPageContent) {
            this.setState({ servicesPageContent: nextProps.servicesPageContent })
        }

    }

    renderParagraph(section) {
        const contents = this.state.servicesPageContent;
        //fetch the string
        let paragraph = contents[section].value;

        return <div dangerouslySetInnerHTML={{__html: paragraph}}></div>
    }

    render() {

        const contents = this.state.servicesPageContent;

        return(
            <div className={"subServiceContainer"}>
                <section className={"structuredCabling box"} ref={(section) => { this.structuredCabling = section; }}>
                    <div className={"imageBox"}>
                        <img src={cabling} alt={'slideShowImage'} />
                    </div>

                    <div>
                        <h1 style={{ textAlign: 'center'}}>STRUCTURED CABLING</h1>
                        <div style={{ textAlign: 'justify'}}>{ contents.structuredCabling ? this.renderParagraph('structuredCabling') : 'Loading...'}</div>
                    </div>
                </section>
            </div>
        );
    }

}

const mapStateToProps = state => {
    const { servicesPageContent } = state.panel;

    return {
        servicesPageContent,
    }
};

export default connect(mapStateToProps)(StructuredCabling);