import React from 'react';
import { connect } from 'react-redux';
import { Radio } from 'antd';

//css imports
import 'antd/es/radio/style/css';

//action imports
import { radioOptionChanged, descriptionUpdate, clearDescriptionBox, changeDescription, } from "../actions/PanelActions";

//component imports
import { Button } from "./common/Index";

class ServicesPanel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            servicesDescription: this.props.servicesDescription,
        };

        this.onServicesRadioChange = this.onServicesRadioChange.bind(this);
        this.onSaveServicesDescription = this.onSaveServicesDescription.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if(nextProps.servicesDescription !== this.props.servicesDescription) {
            this.setState(({ servicesDescription: this.props.servicesDescription }))
        }

    }

    onServicesRadioChange = e => {

        const radioFrom = 'servicesRadio';
        const value = e.target.value;
        this.props.radioOptionChanged(radioFrom, value);

    };

    onSaveServicesDescription() {
        const { servicesRadioValue, servicesDescription } = this.props;

        if (servicesDescription !== "") {
            this.props.changeDescription({ page: 'servicesPage', title: servicesRadioValue, value: servicesDescription });
        } else {
            alert("Please fill in description field");
        }
    }

    render() {
        return(
            <div className={"servicesPanel panel"}>

                <div className={"aboutUsContainer panelContainer"}>
                    <div>
                        <div>
                            <Radio.Group onChange={this.onServicesRadioChange} value={this.props.servicesRadioValue} size="large">
                                <Radio.Button value="cctvSurveillanceSystems">CCTV Surveillance Systems</Radio.Button>
                                <Radio.Button value="electricFence">Electric Fence</Radio.Button>
                                <Radio.Button value="timeAttendance">Time Attendance</Radio.Button>
                                <Radio.Button value="accessControlSystems">Access Control Systems</Radio.Button>
                                <Radio.Button value="fireDetection">Fire Detection / Alarm Systems</Radio.Button>
                                <Radio.Button value="intrusionAlarmSystems">Intrusion Alarm Systems</Radio.Button>
                                <Radio.Button value="audioVideoIntercomSystems">Audio/Video Intercom Systems</Radio.Button>
                                <Radio.Button value="vehicleTrackingSystems">Vehicle Tracking Systems</Radio.Button>
                                <Radio.Button value="gateAutomation">Gate Automation</Radio.Button>
                                <Radio.Button value="audioSystems">Audiovisual Systems</Radio.Button>
                                <Radio.Button value="itInfrastructureAndNetworking">IT Infrastructure & Networking</Radio.Button>
                                <Radio.Button value="structuredCabling">Structured Cabling</Radio.Button>
                                <Radio.Button value="services">Services</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>

                    <div className={"servicesDescription panelDescription"}>
                        <textarea
                            style={{
                                color: '#000',
                            }}
                            rows="10"
                            cols="100"
                            placeholder={ "Description" }
                            value={this.props.servicesDescription}
                            onChange={ e => this.props.descriptionUpdate({ prop: 'servicesDescription', value: e.target.value}) }
                        />
                    </div>

                    <Button
                        onPress={ () => this.onSaveServicesDescription() }
                        text={"Save Services Description"}
                    />
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    const { servicesRadioValue, servicesDescription } = state.panel;

    return {
        servicesRadioValue,
        servicesDescription
    }
};


export default connect(mapStateToProps, { radioOptionChanged, descriptionUpdate, clearDescriptionBox, changeDescription, })(ServicesPanel);