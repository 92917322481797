import React from 'react';


export class Input extends React.Component {
    render() {
        return (
            <div className={'inputContainerStyle'}>
                <div className={'inputLabelContainerStyle'}>
                    {
                        this.props.label ?
                            <p className={'inputLabelStyle'}>
                                { this.props.label }
                            </p> : null
                    }
                </div>
                <input
                    type={ this.props.type }
                    className={'inputStyle'}
                    placeholder={ this.props.placeholder }
                    value={ this.props.value }
                    onChange={ this.props.onChange }
                    name={ this.props.name }
                    accept={this.props.accept}
                />
            </div>
        );
    }
}



