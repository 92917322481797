import React from 'react';
import Carousel from 'react-multi-carousel';
import { connect } from 'react-redux';
import _ from 'lodash';

//css imports
import 'react-multi-carousel/lib/styles.css';

//action imports
import { fetchOurPartnersImages } from "../actions/PanelActions";

//component imports
import { Spinner } from "./common/Index";

//file imports
// import apc from '../assets/gallery/partner_logos/apc.jpeg';
// import cisco from '../assets/gallery/partner_logos/cisco.jpeg';
// import dahua from '../assets/gallery/partner_logos/dahua.jpeg';
// import eaton from '../assets/gallery/partner_logos/eaton.jpeg';
// //import evinetworks from '../assets/gallery/partner_logos/evinetworks.png';
// import hkvision from '../assets/gallery/partner_logos/hikvision.png';
// import nemtek from '../assets/gallery/partner_logos/nemtek.jpeg';
// import risco from '../assets/gallery/partner_logos/risco.jpeg';
// import texecom from '../assets/gallery/partner_logos/texecom.jpeg';
// import yealink from '../assets/gallery/partner_logos/yealink.jpeg';
// import zkteco from '../assets/gallery/partner_logos/zkteco.jpeg';
// import zyxel from '../assets/gallery/partner_logos/zyxel.jpeg';

class OurPartners extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            album: this.props.currentOurPartnersPictures,
        }
    }

    componentWillMount() {
        this.props.fetchOurPartnersImages();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.currentOurPartnersPictures !== this.props.currentOurPartnersPictures) {
            this.setState({ album: nextProps.currentOurPartnersPictures })
        }
    }
    
    render() {

        const album = _.map(this.state.album, imageObj => {
            return {
                src: imageObj.downloadURL,
                thumbnail: imageObj.downloadURL,
                thumbnailWidth: 320,
                thumbnailHeight: 174,
                fileName: imageObj.fileName,
                imageID: imageObj.imageID,
            };

        });

        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 8,
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 7,
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 4,
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 3,
            },
        };

        return(
            <div className={"ourPartners"}>
                <h1>Our Partners</h1>
                <div className={"partners"}>

                    <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={4000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["desktop","tablet", "mobile"]}
                        deviceType={this.props.deviceType}
                        // centerMode={true}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                    >
                        {
                            album ?
                                album.map(image => {
                                    return(
                                        <div key={image.imageID}>
                                            <img src={image.src} alt={'slideShowImage'} />
                                        </div>
                                    );
                                }):
                                <div>
                                    <Spinner/>
                                </div>
                        }
                    </Carousel>;


                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { currentOurPartnersPictures } = state.panel;

    return {
        currentOurPartnersPictures,
    }
};

export default connect(mapStateToProps, { fetchOurPartnersImages })(OurPartners);