import firebase from 'firebase';
import {
    CLEAR_DESCRIPTION_BOX,
    SERVICE_OFFERED_DESCRIPTION_UPDATE,
    FETCH_LANDING_IMAGES_FAILED,
    FETCH_LANDING_IMAGES_SUCCESS,
    LANDING_IMAGE_SELECTED,
    SERVICES_OFFERED_RADIO_VALUE_CHANGED,
    FETCH_LANDING_PAGE_CONTENT_SUCCESSFUL,
    WHY_CHOOSE_US_RADIO_VALUE_CHANGED,
    WHY_CHOOSE_US_DESCRIPTION_UPDATE,
    ABOUT_US_RADIO_VALUE_CHANGED,
    ABOUT_US_DESCRIPTION_UPDATE,
    FETCH_ABOUT_US_PAGE_CONTENT_SUCCESSFUL,
    SERVICES_RADIO_VALUE_CHANGED,
    SERVICES_DESCRIPTION_UPDATE,
    FETCH_SERVICES_PAGE_CONTENT_SUCCESFUL,
    GALLERY_IMAGE_SELECTED,
    FETCH_GALLERY_IMAGES_SUCCESS,
    FETCH_GALLERY_IMAGES_FAILED,
    DESCRIPTION_SAVED_SUCCESSFULLY,
    OUR_PARTNER_IMAGE_SELECTED,
    FETCH_OUR_PARTNERS_IMAGES_SUCCESS,
    FETCH_OUR_PARTNERS_IMAGES_FAILED,
    CLIENT_IMAGE_SELECTED, FETCH_CLIENTS_IMAGES_SUCCESS, FETCH_CLIENTS_IMAGES_FAILED,
} from "./Types";


//--------------------------------------------------- DESCRIPTIONS --------------------------------------------------//

export const radioOptionChanged = (radioFrom,value) => {

    switch (radioFrom) {
        case 'servicesOfferedRadio':
            return {
                type: SERVICES_OFFERED_RADIO_VALUE_CHANGED,
                payload: value
            };
        case "whyChooseUsRadio":
            return {
                type: WHY_CHOOSE_US_RADIO_VALUE_CHANGED,
                payload: value
            };
        case "aboutUsRadio":
            return {
                type: ABOUT_US_RADIO_VALUE_CHANGED,
                payload: value
            };
        case "servicesRadio":
            return {
                type: SERVICES_RADIO_VALUE_CHANGED,
                payload: value
            };
        default:
            break;
    }
};

export const descriptionUpdate = ({ prop, value}) => {

    switch (prop) {
        case "servicesOfferedDescription":
            return {
                type: SERVICE_OFFERED_DESCRIPTION_UPDATE,
                payload:  value
            };
        case "whyChooseUsDescription":
            return {
                type: WHY_CHOOSE_US_DESCRIPTION_UPDATE,
                payload:  value
            };
        case "aboutUsDescription":
            return {
                type: ABOUT_US_DESCRIPTION_UPDATE,
                payload: value
            };
        case "servicesDescription":
            return {
                type: SERVICES_DESCRIPTION_UPDATE,
                payload: value
            };
        default:
            break;
    }
};

export const clearDescriptionBox = () => {
    return {
        type: CLEAR_DESCRIPTION_BOX
    }
};

export const changeDescription = ({ page, title, value }) => {

    console.log(page);
    console.log(title);
    console.log(value);

    //fetch current user from firebase
    const user = firebase.auth().currentUser;

    return(dispatch) => {
        //summon database and make changes to it
        firebase.firestore().collection('users').doc("admins").collection(user.uid).doc("public").collection(`${page}`).doc("info")
            .set({ [title]: { value } }, { merge: true })
            .then(() => {
                console.log("data is saved successfully");
                dispatch({
                    type: DESCRIPTION_SAVED_SUCCESSFULLY
                })
            })
            .catch((err) => {
                console.log(err);
            })
    }

};

export const fetchLandingPageContent = () => {

    return(dispatch) => {
        firebase.firestore().collection('users').doc("admins").collection("UbmU3WjVolO3j3x8gn967fQpQbZ2").doc("public").collection("landingPage").doc("info")
            .onSnapshot((doc) => {
                if (doc.exists) {
                    const data = doc.data();

                    //send fetched data to reducer
                    dispatch({
                        type: FETCH_LANDING_PAGE_CONTENT_SUCCESSFUL,
                        payload: data
                    });


                } else {
                    console.log("Empty Landing Page");
                }
            }, function (error) {
                console.log("failed to execute fetch landing page function");
                console.log(error);
            })
    };

};

export const fetchAboutUsPageContent = () => {

    return(dispatch) => {
        firebase.firestore().collection('users').doc("admins").collection("UbmU3WjVolO3j3x8gn967fQpQbZ2").doc("public").collection("aboutUsPage").doc("info")
            .onSnapshot((doc) => {
                if (doc.exists) {
                    const data = doc.data();

                    //send fetched data to reducer
                    dispatch({
                        type: FETCH_ABOUT_US_PAGE_CONTENT_SUCCESSFUL,
                        payload: data
                    });


                } else {
                    console.log("Empty about us Page");
                }
            }, function (error) {
                console.log("failed to execute fetch about us page function");
                console.log(error);
            })
    };

};


export const fetchServicesPageContent = () => {

    return(dispatch) => {
        firebase.firestore().collection('users').doc("admins").collection("UbmU3WjVolO3j3x8gn967fQpQbZ2").doc("public").collection("servicesPage").doc("info")
            .onSnapshot((doc) => {
                if (doc.exists) {
                    const data = doc.data();

                    //send fetched data to reducer
                    dispatch({
                        type: FETCH_SERVICES_PAGE_CONTENT_SUCCESFUL,
                        payload: data
                    });


                } else {
                    console.log("Empty Services Page");
                }
            }, function (error) {
                console.log("failed to execute fetch services page function");
                console.log(error);
            })
    };

};


//--------------------------------------------------- LANDING PICTURES --------------------------------------------------//

export const landingImagesChanged = (imageArray) => {
    console.log("images received");
    console.log(imageArray);
    const user = firebase.auth().currentUser;



    return() => {
        console.log("return statement opened");
        //extract name from every image in the array and post it
        for (let i = 0; i < imageArray.length; i++) {
            console.log("image entered in for loop");
            console.log(i);
            const imageValue = imageArray[i];
            const imageID = Math.random().toString(36).substring(6).toUpperCase();
            const fileName = imageValue.name;

            // //delete previous file
            // if (posterFileName) {
            //     firebase.storage().ref(`users/admins/${user.uid}/public/landingImages/${posterFileName}`)
            //         .delete().then(() => {
            //         console.log('successfully deleted previous poster file for this movie')
            //     }).catch((error) => {
            //         console.log('error deleting previous poster of this movie');
            //         console.log(error);
            //     })
            // }

            const uploadTask = firebase.storage().ref(`users/admins/${user.uid}/public/landingImages/${imageID}`)
                .put(imageValue);

            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                function(snapshot) {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                            console.log('Upload is paused');
                            break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                            console.log('Upload is running');
                            break;
                        default:
                    }
                }, function(error) {

                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;

                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                        default:
                    }
                }, function() {
                    // Upload completed successfully, now we can get the download URL
                    uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                        console.log('File available at', downloadURL);

                        //post downloadURL and file name to an object in database
                        firebase.firestore().collection('users').doc("admins").collection(user.uid).doc("public").collection("landingImages").doc("info")
                            .set({ [imageID]: { fileName, downloadURL, imageID } }, { merge: true })
                            .then(() => {
                                //successfully posted image details to database
                                console.log("successfully posted image to database");

                            }).catch((error) => {
                            console.log(`there was an error posting the image ${fileName}`);
                            console.log(error);
                        });
                    });
                });
        }
    };
};

export const fetchLandingImages = () => {

    //fetch current user from firebase


    //fetch landing images
    return(dispatch) => {
        console.log("fetch landing called");
        firebase.firestore().collection('users').doc('admins').collection("UbmU3WjVolO3j3x8gn967fQpQbZ2").doc("public").collection("landingImages").doc("info")
            .onSnapshot(function (doc) {
                //check if doc is available
                if (doc.exists) {
                    //fetch the data from the available doc
                    const data = doc.data();

                    //send the data to the reducers
                    dispatch({
                        type: FETCH_LANDING_IMAGES_SUCCESS,
                        payload: data
                    });

                } else {
                    console.log("doc doesn't exists");
                }
            }, function (error) {
                console.log("Error fetching landing images");
                console.log(error);
                dispatch({ type: FETCH_LANDING_IMAGES_FAILED })
            })
    }
};

export const landingImageSelected = (selectedImages) => {
    return {
        type: LANDING_IMAGE_SELECTED,
        payload: selectedImages
    }
};


export const removeLandingPictures = (selectedLandingImages) => {

    //fetch current user from firebase
    const user = firebase.auth().currentUser;

    return() => {
        for (let i = 0; i < selectedLandingImages.length; i++) {
            const image = selectedLandingImages[i];
            const imageID = image.imageID;

            //delete image file from storage
            if (imageID) {
                firebase.storage().ref(`users/admins/${user.uid}/public/landingImages/${imageID}`)
                    .delete().then(() => {
                    console.log('successfully deleted image file from storage')
                }).catch((error) => {
                    console.log('error deleting image file from storage');
                    console.log(error);
                })
            }

            //delete image details from database
            firebase.firestore().collection('users').doc("admins").collection(user.uid).doc("public").collection("landingImages").doc("info")
                .update({ [imageID]: firebase.firestore.FieldValue.delete() })
                .then(() => {
                    console.log("image deleted successfully from database");
                }).catch((error) => {
                console.log("image failed to delete from database");
                console.log(error);
            })

        }
    };
};


//------------------------------------------------------ GALLERY PICTURES --------------------------------------------------//

export const galleryImagesChanged = (imageArray) => {
    console.log("images received");
    console.log(imageArray);
    const user = firebase.auth().currentUser;



    return() => {
        console.log("return statement opened");
        //extract name from every image in the array and post it
        for (let i = 0; i < imageArray.length; i++) {
            console.log("image entered in for loop");
            console.log(i);
            const imageValue = imageArray[i];
            const imageID = Math.random().toString(36).substring(6).toUpperCase();
            const fileName = imageValue.name;

            // //delete previous file
            // if (posterFileName) {
            //     firebase.storage().ref(`users/admins/${user.uid}/public/landingImages/${posterFileName}`)
            //         .delete().then(() => {
            //         console.log('successfully deleted previous poster file for this movie')
            //     }).catch((error) => {
            //         console.log('error deleting previous poster of this movie');
            //         console.log(error);
            //     })
            // }

            const uploadTask = firebase.storage().ref(`users/admins/${user.uid}/public/galleryImages/${imageID}`)
                .put(imageValue);

            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                function(snapshot) {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                            console.log('Upload is paused');
                            break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                            console.log('Upload is running');
                            break;
                        default:
                    }
                }, function(error) {

                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;

                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                        default:
                    }
                }, function() {
                    // Upload completed successfully, now we can get the download URL
                    uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                        console.log('File available at', downloadURL);

                        //post downloadURL and file name to an object in database
                        firebase.firestore().collection('users').doc("admins").collection(user.uid).doc("public").collection("galleryImages").doc("info")
                            .set({ [imageID]: { fileName, downloadURL, imageID } }, { merge: true })
                            .then(() => {
                                //successfully posted image details to database
                                console.log("successfully posted image to database");

                            }).catch((error) => {
                            console.log(`there was an error posting the image ${fileName}`);
                            console.log(error);
                        });
                    });
                });
        }
    };
};

export const galleryImageSelected = (selectedImages) => {
    return {
        type: GALLERY_IMAGE_SELECTED,
        payload: selectedImages
    }
};

export const removeGalleryPictures = (selectedGalleryImages) => {

    //fetch current user from firebase
    const user = firebase.auth().currentUser;

    return() => {
        for (let i = 0; i < selectedGalleryImages.length; i++) {
            const image = selectedGalleryImages[i];
            const imageID = image.imageID;

            //delete image file from storage
            if (imageID) {
                firebase.storage().ref(`users/admins/${user.uid}/public/galleryImages/${imageID}`)
                    .delete().then(() => {
                    console.log('successfully deleted image file from storage')
                }).catch((error) => {
                    console.log('error deleting image file from storage');
                    console.log(error);
                })
            }

            //delete image details from database
            firebase.firestore().collection('users').doc("admins").collection(user.uid).doc("public").collection("galleryImages").doc("info")
                .update({ [imageID]: firebase.firestore.FieldValue.delete() })
                .then(() => {
                    console.log("image deleted successfully from database");
                }).catch((error) => {
                console.log("image failed to delete from database");
                console.log(error);
            })

        }
    };
};

export const fetchGalleryImages = () => {

    //fetch current user from firebase


    //fetch landing images
    return(dispatch) => {
        firebase.firestore().collection('users').doc('admins').collection("UbmU3WjVolO3j3x8gn967fQpQbZ2").doc("public").collection("galleryImages").doc("info")
            .onSnapshot(function (doc) {
                //check if doc is available
                if (doc.exists) {
                    //fetch the data from the available doc
                    const data = doc.data();

                    //send the data to the reducers
                    dispatch({
                        type: FETCH_GALLERY_IMAGES_SUCCESS,
                        payload: data
                    });

                } else {
                    console.log("doc doesn't exists");
                }
            }, function (error) {
                console.log("Error fetching landing images");
                console.log(error);
                dispatch({ type: FETCH_GALLERY_IMAGES_FAILED })
            })
    }
};


//------------------------------------------------------ OUR PARTNERS PICTURES --------------------------------------------------//

export const ourPartnersChanged = (imageArray) => {
    console.log("images received");
    console.log(imageArray);
    const user = firebase.auth().currentUser;



    return() => {
        console.log("return statement opened");
        //extract name from every image in the array and post it
        for (let i = 0; i < imageArray.length; i++) {
            console.log("image entered in for loop");
            console.log(i);
            const imageValue = imageArray[i];
            const imageID = Math.random().toString(36).substring(6).toUpperCase();
            const fileName = imageValue.name;

            // //delete previous file
            // if (posterFileName) {
            //     firebase.storage().ref(`users/admins/${user.uid}/public/landingImages/${posterFileName}`)
            //         .delete().then(() => {
            //         console.log('successfully deleted previous poster file for this movie')
            //     }).catch((error) => {
            //         console.log('error deleting previous poster of this movie');
            //         console.log(error);
            //     })
            // }

            const uploadTask = firebase.storage().ref(`users/admins/${user.uid}/public/ourPartnersImages/${imageID}`)
                .put(imageValue);

            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                function(snapshot) {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                            console.log('Upload is paused');
                            break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                            console.log('Upload is running');
                            break;
                        default:
                    }
                }, function(error) {

                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;

                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                        default:
                    }
                }, function() {
                    // Upload completed successfully, now we can get the download URL
                    uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                        console.log('File available at', downloadURL);

                        //post downloadURL and file name to an object in database
                        firebase.firestore().collection('users').doc("admins").collection(user.uid).doc("public").collection("ourPartnersImages").doc("info")
                            .set({ [imageID]: { fileName, downloadURL, imageID } }, { merge: true })
                            .then(() => {
                                //successfully posted image details to database
                                console.log("successfully posted image to database");

                            }).catch((error) => {
                            console.log(`there was an error posting the image ${fileName}`);
                            console.log(error);
                        });
                    });
                });
        }
    };
};

export const ourPartnersImageSelected = (selectedImages) => {
    return {
        type: OUR_PARTNER_IMAGE_SELECTED,
        payload: selectedImages
    }
};

export const removeOurPartnersPictures = (selectedGalleryImages) => {

    //fetch current user from firebase
    const user = firebase.auth().currentUser;

    return() => {
        for (let i = 0; i < selectedGalleryImages.length; i++) {
            const image = selectedGalleryImages[i];
            const imageID = image.imageID;

            //delete image file from storage
            if (imageID) {
                firebase.storage().ref(`users/admins/${user.uid}/public/ourPartnersImages/${imageID}`)
                    .delete().then(() => {
                        //code execution
                        console.log('successfully deleted image file from storage')
                }).catch((error) => {
                        console.log('error deleting image file from storage');
                        console.log(error);
                })
            }

            //delete image details from database
            firebase.firestore().collection('users').doc("admins").collection(user.uid).doc("public").collection("ourPartnersImages").doc("info")
                .update({ [imageID]: firebase.firestore.FieldValue.delete() })
                .then(() => {
                    console.log("image deleted successfully from database");
                }).catch((error) => {
                console.log("image failed to delete from database");
                console.log(error);
            })

        }
    };
};

export const fetchOurPartnersImages = () => {

    //fetch current user from firebase


    //fetch landing images
    return(dispatch) => {
        firebase.firestore().collection('users').doc('admins').collection("UbmU3WjVolO3j3x8gn967fQpQbZ2").doc("public").collection("ourPartnersImages").doc("info")
            .onSnapshot(function (doc) {
                //check if doc is available
                if (doc.exists) {
                    //fetch the data from the available doc
                    const data = doc.data();

                    //send the data to the reducers
                    dispatch({
                        type: FETCH_OUR_PARTNERS_IMAGES_SUCCESS,
                        payload: data
                    });

                } else {
                    console.log("doc doesn't exists");
                }
            }, function (error) {
                console.log("Error fetching landing images");
                console.log(error);
                dispatch({ type: FETCH_OUR_PARTNERS_IMAGES_FAILED })
            })
    }
};


//------------------------------------------------------ OUR CLIENTS PICTURES --------------------------------------------------//

export const clientsChanged = (imageArray) => {
    console.log("images received");
    console.log(imageArray);
    const user = firebase.auth().currentUser;



    return() => {
        console.log("return statement opened");
        //extract name from every image in the array and post it
        for (let i = 0; i < imageArray.length; i++) {
            console.log("image entered in for loop");
            console.log(i);
            const imageValue = imageArray[i];
            const imageID = Math.random().toString(36).substring(6).toUpperCase();
            const fileName = imageValue.name;

            // //delete previous file
            // if (posterFileName) {
            //     firebase.storage().ref(`users/admins/${user.uid}/public/landingImages/${posterFileName}`)
            //         .delete().then(() => {
            //         console.log('successfully deleted previous poster file for this movie')
            //     }).catch((error) => {
            //         console.log('error deleting previous poster of this movie');
            //         console.log(error);
            //     })
            // }

            const uploadTask = firebase.storage().ref(`users/admins/${user.uid}/public/clientsImages/${imageID}`)
                .put(imageValue);

            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                function(snapshot) {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                            console.log('Upload is paused');
                            break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                            console.log('Upload is running');
                            break;
                        default:
                    }
                }, function(error) {

                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;

                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                        default:
                    }
                }, function() {
                    // Upload completed successfully, now we can get the download URL
                    uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                        console.log('File available at', downloadURL);

                        //post downloadURL and file name to an object in database
                        firebase.firestore().collection('users').doc("admins").collection(user.uid).doc("public").collection("clientsImages").doc("info")
                            .set({ [imageID]: { fileName, downloadURL, imageID } }, { merge: true })
                            .then(() => {
                                //successfully posted image details to database
                                console.log("successfully posted image to database");

                            }).catch((error) => {
                            console.log(`there was an error posting the image ${fileName}`);
                            console.log(error);
                        });
                    });
                });
        }
    };
};

export const clientsImageSelected = (selectedImages) => {
    return {
        type: CLIENT_IMAGE_SELECTED,
        payload: selectedImages
    }
};

export const removeClientsPictures = (selectedGalleryImages) => {

    //fetch current user from firebase
    const user = firebase.auth().currentUser;

    return() => {
        for (let i = 0; i < selectedGalleryImages.length; i++) {
            const image = selectedGalleryImages[i];
            const imageID = image.imageID;

            //delete image file from storage
            if (imageID) {
                firebase.storage().ref(`users/admins/${user.uid}/public/clientsImages/${imageID}`)
                    .delete().then(() => {
                    console.log('successfully deleted image file from storage')
                }).catch((error) => {
                    console.log('error deleting image file from storage');
                    console.log(error);
                })
            }

            //delete image details from database
            firebase.firestore().collection('users').doc("admins").collection(user.uid).doc("public").collection("clientsImages").doc("info")
                .update({ [imageID]: firebase.firestore.FieldValue.delete() })
                .then(() => {
                    console.log("image deleted successfully from database");
                }).catch((error) => {
                console.log("image failed to delete from database");
                console.log(error);
            })

        }
    };
};

export const fetchClientsImages = () => {

    //fetch current user from firebase


    //fetch landing images
    return(dispatch) => {
        firebase.firestore().collection('users').doc('admins').collection("UbmU3WjVolO3j3x8gn967fQpQbZ2").doc("public").collection("clientsImages").doc("info")
            .onSnapshot(function (doc) {
                //check if doc is available
                if (doc.exists) {
                    //fetch the data from the available doc
                    const data = doc.data();

                    //send the data to the reducers
                    dispatch({
                        type: FETCH_CLIENTS_IMAGES_SUCCESS,
                        payload: data
                    });

                } else {
                    console.log("doc doesn't exists");
                }
            }, function (error) {
                console.log("Error fetching landing images");
                console.log(error);
                dispatch({ type: FETCH_CLIENTS_IMAGES_FAILED })
            })
    }
};