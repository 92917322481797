import {CLEAR_SUB_SERVICE, SHOW_SUB_SERVICE} from "../actions/Types";

const INITIAL_STATE = {
    subService: ''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAR_SUB_SERVICE:
            return { ...state, subService: '' };
        case SHOW_SUB_SERVICE:
            return { ...state, subService: action.payload };
        default:
            return state;
    }
}