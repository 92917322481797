import {
    CONTACT_EMAIL_UPDATED,
    CONTACT_NAME_UPDATED, CONTACT_REQUEST_SENT_SUCCESSFULLY,
    CONTACT_REQUEST_UPDATED,
    FETCH_MESSAGES_SUCCESSFUL
} from "../actions/Types";

const INITIAL_STATE = {
    contactName: '',
    contactEmail: '',
    contactRequest: '',
    messages: {}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_MESSAGES_SUCCESSFUL:
            return { ...state, messages: action.payload };
        case CONTACT_REQUEST_SENT_SUCCESSFULLY:
            return { ...state, contactName: '', contactEmail: '', contactRequest: '' };
        case CONTACT_NAME_UPDATED:
            return { ...state, contactName: action.payload };
        case CONTACT_EMAIL_UPDATED:
            return { ...state, contactEmail: action.payload };
        case CONTACT_REQUEST_UPDATED:
            return { ...state, contactRequest: action.payload };
        default:
            return state;
    }
}