import React from 'react';
import { connect } from 'react-redux';


//file imports
import services from '../assets/icons/services/services.png';
import Tools from "./Tools";

class Services extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            servicesPageContent: this.props.servicesPageContent,
        };

        this.renderParagraph = this.renderParagraph.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.servicesPageContent !== this.props.servicesPageContent) {
            this.setState({ servicesPageContent: nextProps.servicesPageContent })
        }
    }

    renderParagraph(section) {
        const contents = this.state.servicesPageContent;
        //fetch the string
        let paragraph = contents[section].value;

        return <div dangerouslySetInnerHTML={{__html: paragraph}}></div>
    }

    render() {

        const contents = this.state.servicesPageContent;

        return(
            <div className={"services"}>
                <div>
                    <Tools/>
                </div>

                <div className={"servicesInfo"}>
                    <div className={"serviceDescription"}>
                        <div>{ contents.services ? this.renderParagraph('services') : 'Loading...'}</div>
                    </div>

                    <div className={"imageBox"}>
                        <img src={services} alt={'slideShowImage'} />
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    const { servicesPageContent } = state.panel;

    return {
        servicesPageContent
    }
};

export default connect(mapStateToProps)(Services);