import React from 'react';
import { connect } from 'react-redux';
import ImageUploader from 'react-images-upload';
import Gallery from 'react-grid-gallery';
import _ from 'lodash';
import { Radio } from 'antd';

//css imports
import 'antd/es/radio/style/css';

//action imports
import { landingImagesChanged, fetchLandingImages, landingImageSelected, removeLandingPictures, radioOptionChanged, descriptionUpdate, clearDescriptionBox,
    changeDescription, } from "../actions/PanelActions";

//component imports
import { Button } from "./common/Index";

class HomePanel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            landingPictures: [],
            currentLandingPictures: [],
            selectedLandingImages: this.props.selectedLandingImages,
            servicesOfferedDescription: this.props.servicesOfferedDescription,
            whyChooseUsDescription: this.props.whyChooseUsDescription,
        };

        this.onLandingDrop = this.onLandingDrop.bind(this);
        // this.onGalleryDrop = this.onGalleryDrop.bind(this);
        this.uploadLandingPictures = this.uploadLandingPictures.bind(this);
        // this.uploadGalleryPictures = this.uploadGalleryPictures.bind(this);
        this.onLandingRemove = this.onLandingRemove.bind(this);
        // this.onGalleryRemove = this.onGalleryRemove.bind(this);
        this.onSelectImage = this.onSelectImage.bind(this);
        // this.onGallerySelectImage = this.onGallerySelectImage.bind(this);
        this.onServicesOfferedRadioChange = this.onServicesOfferedRadioChange.bind(this);
        this.onSaveServiceDescription = this.onSaveServiceDescription.bind(this);
        this.onWhyChooseUsRadioChange = this.onWhyChooseUsRadioChange.bind(this);
        this.onSaveWhyChooseUsDescription = this.onSaveWhyChooseUsDescription.bind(this);
    }

    componentWillMount() {
        //fetch the current landing images
        this.props.fetchLandingImages();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        //check if current landing pictures in next props is different
        if(nextProps.currentLandingPictures !== this.props.currentLandingPictures) {
            let currentLandingImagesArray = _.map(nextProps.currentLandingPictures, imageObj => {
                const image = {
                    src: imageObj.downloadURL,
                    thumbnail: imageObj.downloadURL,
                    thumbnailWidth: 320,
                    thumbnailHeight: 174,
                    fileName: imageObj.fileName,
                    imageID: imageObj.imageID,
                };
                return image;
            });

            this.setState({ currentLandingPictures: currentLandingImagesArray })
        }


        if(nextProps.selectedLandingImages !== this.props.selectedLandingImages) {
            this.setState({ selectedLandingImages: nextProps.selectedLandingImages })
        }

        if(nextProps.servicesOfferedDescription !== this.props.servicesOfferedDescription) {
            this.setState({ servicesOfferedDescription: this.props.servicesOfferedDescription })
        }

        if(nextProps.whyChooseUsDescription !== this.props.whyChooseUsDescription) {
            this.setState(({ whyChooseUsDescription: this.props.whyChooseUsDescription }))
        }

    }

    onLandingDrop(picture) {
        this.setState({
            landingPictures: this.state.landingPictures.concat(picture),
        });
    }

    onLandingRemove() {
        const { selectedLandingImages } = this.state;
        console.log("selected images sent to actions");
        console.log(selectedLandingImages);

        if (selectedLandingImages) {
            this.props.removeLandingPictures(selectedLandingImages);
        } else {
            console.log("select images to remove")
        }
    }

    uploadLandingPictures() {
        //take images from state and upload them
        const imageArray = this.state.landingPictures;

        //if there is a similar image in the array, delete duplicate
        let uniqueImagesArray = [...new Set(imageArray)];

        //send images to actions
        console.log("images sent to firebase");
        console.log(uniqueImagesArray);
        this.props.landingImagesChanged(uniqueImagesArray);
    }

    onSelectImage(index, image) {
        console.log(image);
        let images = this.state.currentLandingPictures.slice();

        let img = images[index];

        //check that array is not empty
        if (img) {
            if (img.hasOwnProperty("isSelected"))
                img.isSelected = !img.isSelected;
            else
                img.isSelected = true;
        }

        if (img.hasOwnProperty("isSelected")) {
            if(img.isSelected) {

                //put selected image in an array
                let selectedImages = this.state.selectedLandingImages;

                //check if array from state is not undefined
                if (selectedImages) {
                    //push the new selected image into array from state
                    selectedImages.push(img);
                    //set state with altered array
                    this.props.landingImageSelected(selectedImages);
                } else {
                    //selected images state was undefined so change to array
                    selectedImages = [];
                    //push the new selected image into array from state
                    selectedImages.push(img);

                    //undefined array changed to usable array now set it at state
                    this.props.landingImageSelected(selectedImages);
                }

            } else {
                //image is unselected

                //fetch ID of unselected image
                const id = img.imageID;
                //remove unselected image from selected array
                let selectedImages = this.state.selectedLandingImages;

                //loop over each image in array to find an id that matches
                for (let i=0; i < selectedImages.length; i++) {
                    //if id matches, remove the element using index number
                    if ( selectedImages[i].imageID === id ) {
                        selectedImages.splice(i, 1);
                    }
                }

                //successfully removed image from selected array post new array to state
                this.props.landingImageSelected(selectedImages);
            }
        }
    }

    onServicesOfferedRadioChange = e => {

        const radioFrom = 'servicesOfferedRadio';
        const value = e.target.value;
        this.props.radioOptionChanged(radioFrom, value);

        //clear service description box
        // this.props.clearDescriptionBox();
    };

    onSaveServiceDescription() {
        const { servicesOfferedRadioValue, servicesOfferedDescription, } = this.props;

        if (servicesOfferedDescription !== "") {
            this.props.changeDescription({ page: 'landingPage', title: servicesOfferedRadioValue, value: servicesOfferedDescription });
        } else {
            alert("Please fill in description field");
        }
    }

    onWhyChooseUsRadioChange = e => {

        const radioFrom = 'whyChooseUsRadio';
        const value = e.target.value;
        this.props.radioOptionChanged(radioFrom, value);

    };

    onSaveWhyChooseUsDescription() {
        const { whyChooseUsRadioValue, whyChooseUsDescription } = this.props;

        if (whyChooseUsDescription !== "") {
            this.props.changeDescription({ page: 'landingPage', title: whyChooseUsRadioValue, value: whyChooseUsDescription });
        } else {
            alert("Please fill in description field");
        }

    }

    render() {

        // let descriptionValue;
        //
        // //check for the prop with data
        // const { cctvCamera, electricFence, timerAttendance, accessControl, fireDetection, intrusionSystem, } = this.props;
        //
        // const dataArray = [ cctvCamera, electricFence, timerAttendance, accessControl, fireDetection, intrusionSystem ];
        //
        // const description = dataArray.filter( description => description !== '');
        //
        // console.log(description);
        //
        // if (description[0] === false) {
        //     descriptionValue = ''
        // } else {
        //     descriptionValue = description[0];
        // }

        return(
            <div className={"homePanel"}>

                <div className={"landingPageImages"}>

                    <div className={"galleryPageImagesContainer"}>
                        <h2>Landing Page Images</h2>
                        <ImageUploader
                            withIcon={true}
                            buttonText='Choose images'
                            onChange={this.onLandingDrop}
                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                            maxFileSize={52428800}
                        />
                        <div className={"galleryBtn"}>
                            <Button
                                text={'Upload'}
                                onPress={() => this.uploadLandingPictures()}
                            />
                        </div>
                    </div>

                    <hr/>

                    <div className={"servicesOfferedContainer panelContainer"}>
                        <div className={"servicesOffered"}>
                            <h2>Service Offered</h2>
                            <div>
                                <Radio.Group onChange={this.onServicesOfferedRadioChange} value={this.props.servicesOfferedRadioValue} size="large">
                                    <Radio.Button value="cctvCamera">CCTV Camera</Radio.Button>
                                    <Radio.Button value="electricFence">Electric Fence</Radio.Button>
                                    <Radio.Button value="timerAttendance">Time Attendance</Radio.Button>
                                    <Radio.Button value="accessControl">Access Control</Radio.Button>
                                    <Radio.Button value="fireDetection">Fire Detection</Radio.Button>
                                    <Radio.Button value="intrusionSystem">Intrusion System</Radio.Button>
                                </Radio.Group>
                            </div>
                        </div>

                        <div className={"servicesOfferedDescription panelDescription"}>
                        <textarea
                            style={{
                                color: '#000',
                            }}
                            rows="10"
                            cols="100"
                            placeholder={ "Description of service" }
                            value={this.props.servicesOfferedDescription}
                            onChange={ e => this.props.descriptionUpdate({ prop: 'servicesOfferedDescription', value: e.target.value}) }
                        />
                        </div>

                        <Button
                            onPress={ () => this.onSaveServiceDescription() }
                            text={"Save Service Description"}
                        />
                    </div>

                    <hr/>

                    <div className={"whyChooseUsContainer panelContainer"}>
                        <div className={"whyChoose"}>
                            <h2>Why Choose Us</h2>
                            <div>
                                <Radio.Group onChange={this.onWhyChooseUsRadioChange} value={this.props.whyChooseUsRadioValue} size="large">
                                    <Radio.Button value="experience">Experience</Radio.Button>
                                    <Radio.Button value="quality">Quality</Radio.Button>
                                    <Radio.Button value="affordability">Affordability</Radio.Button>
                                </Radio.Group>
                            </div>
                        </div>

                        <div className={"whyChooseUsDescription panelDescription"}>
                        <textarea
                            style={{
                                color: '#000',
                            }}
                            rows="10"
                            cols="100"
                            placeholder={ "Description of service" }
                            value={this.props.whyChooseUsDescription}
                            onChange={ e => this.props.descriptionUpdate({ prop: 'whyChooseUsDescription', value: e.target.value}) }
                        />
                        </div>

                        <Button
                            onPress={ () => this.onSaveWhyChooseUsDescription() }
                            text={"Save Why Choose Us Description"}
                        />
                    </div>

                    <hr className={"hr"}/>

                    <div className={"currentImages"}>
                        <h2>Manage Current Images</h2>
                        <div className={"currentImagesContainer"}>
                            <div className={"imagesContainer"}>
                                <h3>Current Landing Images</h3>
                                <div className={"galleryBtn"}>
                                    <Button
                                        text={'Remove selected landing images'}
                                        onPress={() => this.onLandingRemove()}
                                    />
                                </div>

                                <Gallery
                                    images={this.state.currentLandingPictures}
                                    enableImageSelection={true}
                                    onSelectImage={this.onSelectImage}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    const { currentLandingPictures, selectedLandingImages, servicesOfferedRadioValue, cctvCamera,
        electricFence, timerAttendance, accessControl, fireDetection, intrusionSystem, servicesOfferedDescription, whyChooseUsRadioValue,
        whyChooseUsDescription } = state.panel;

    return {
        currentLandingPictures,
        selectedLandingImages,
        servicesOfferedRadioValue,
        cctvCamera,
        electricFence,
        timerAttendance,
        accessControl,
        fireDetection,
        intrusionSystem,
        servicesOfferedDescription,
        whyChooseUsRadioValue,
        whyChooseUsDescription
    }
};

export default connect(mapStateToProps, { landingImagesChanged, fetchLandingImages, landingImageSelected, removeLandingPictures, radioOptionChanged,
    descriptionUpdate, clearDescriptionBox, changeDescription
})(HomePanel);