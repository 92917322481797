import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Carousel } from 'antd';
import 'antd/es/carousel/style/css';

//component imports
import { Spinner } from "./common/Index";
import LandingPageBody from "./LandingPageBody";
import OurPartners from "./OurPartners";
import WhyChooseUs from "./WhyChooseUs";
import HappyClients from "./HappyClients";

//file imports
// import land from '../assets/gallery/land.JPG';
// import land5 from '../assets/gallery/land5.JPG';
// import land14 from '../assets/gallery/land14.JPG';
// import land21 from '../assets/gallery/land21.JPG';


class LandingPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            album: this.props.currentLandingPictures
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.currentLandingPictures !== this.props.currentLandingPictures) {
            console.log("new images recievec");
            this.setState({ album: nextProps.currentLandingPictures })
        }
    }

    render() {
        const album = _.map(this.state.album, image => {
            return image;
        });

        console.log("here's the album");
        console.log(album);

        return(
            <div className={"landingPage"}>
                <div className={"carouselContainer"}>
                    <Carousel
                        autoplay
                    >
                        {
                            album ?
                                album.map(image => {
                                    return(
                                        <div className={"slideShowContentContainer"} key={image.imageID}>
                                            <img src={image.downloadURL} className={"carouselImage"} alt={'slideShowImage'} />
                                        </div>
                                    );
                                }):
                                <div>
                                    <Spinner/>
                                </div>
                        }
                    </Carousel>
                </div>

                <div className={"landingPageContent"}>
                    <div className="bg"> </div>
                    <div className="bg bg2"> </div>
                    <div className="bg bg3"> </div>

                    <div className={"content"}>
                        <LandingPageBody/>
                    </div>

                    <div className={"content"}>
                        <WhyChooseUs/>
                    </div>

                    <div className="brandContent">
                        <OurPartners/>
                    </div>

                    <div className="clientContent">
                        <HappyClients/>
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    //state
    const { currentLandingPictures } = state.panel;

    return {
        currentLandingPictures
    }
};

export default connect(mapStateToProps)(LandingPage);