import React from 'react';
import firebase from "firebase";
import Tabs from 'react-web-tabs/lib/Tabs';
import Tab from 'react-web-tabs/lib/Tab';
import TabPanel from 'react-web-tabs/lib/TabPanel';
import TabList from 'react-web-tabs/lib/TabList';
import 'react-web-tabs/dist/react-web-tabs.css';


//component imports
import HomePanel from "./HomePanel";
import OurPartnersPanel from "./subPanels/OurPartnersPanel";
import ClientsPanel from "./subPanels/ClientsPanel";
import AboutUsPanel from './AboutUsPanel';
import ServicesPanel from './ServicesPanel';
import GalleryPanel from "./GalleryPanel";
import ContactUsPanel from "./ContactUsPanel";


class AdminPanel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loggedIn: false,
            landingPagePictures: []
        };

        this.onDrop = this.onDrop.bind(this);
    }

    componentWillMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({ loggedIn: true });
            }
            else {
                this.setState({ loggedIn: false })
            }
        });
    }

    onDrop(picture) {
        let pictureArray = this.state.landingPagePictures;
        let newArray = pictureArray.concat(picture);

        this.setState({
            landingPagePictures: newArray,
        });

        console.log("landing page pictures array:");
        console.log(newArray);
    }

    renderComponent() {
        if (this.state.loggedIn) {
            return (
                <div className={"adminPanel"}>
                    <Tabs defaultTab="vertical-tab-one" vertical>
                        <TabList
                            className="my-tablist"
                        >
                            <Tab tabFor="vertical-tab-one">Home</Tab>
                            <Tab tabFor="vertical-tab-two">Partners</Tab>
                            <Tab tabFor="vertical-tab-three">Clients</Tab>
                            <Tab tabFor="vertical-tab-four">AboutUs</Tab>
                            <Tab tabFor="vertical-tab-five">Services</Tab>
                            <Tab tabFor="vertical-tab-six">Gallery</Tab>
                            <Tab tabFor="vertical-tab-seven">Contact</Tab>
                        </TabList>
                        <TabPanel tabId="vertical-tab-one">
                            <HomePanel/>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-two">
                            <OurPartnersPanel/>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-three">
                            <ClientsPanel/>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-four">
                            <AboutUsPanel/>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-five">
                            <ServicesPanel/>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-six">
                            <GalleryPanel/>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-seven">
                            <ContactUsPanel/>
                        </TabPanel>
                    </Tabs>


                </div>
            );
        } else {
            return (
                <div>
                    <h1>Admin is not logged in</h1>
                </div>
            );
        }
    }

    render() {
        return (
            <div className={"adminPanelContainer"}>

                { this.renderComponent() }

            </div>
        );
    }
}

export default AdminPanel;