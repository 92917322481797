import React from 'react';
import { connect } from 'react-redux';

//action imports
import { contactRequestUpdated, sendMessage } from "../actions/ContactActions";

//component imports
import LocationMap from "./LocationMap";
import { Button, Input } from "./common/Index";

class Contact extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            name: this.props.contactName,
            email: this.props.contactEmail,
            request: this.props.contactRequest
        };

        this.sendContactMessage = this.sendContactMessage.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.contactName !== this.props.contactName) {
            this.setState({ name: nextProps.contactName })
        }

        if (nextProps.contactEmail !== this.props.contactEmail) {
            this.setState({ email: nextProps.contactEmail })
        }

        if (nextProps.contactRequest !== this.props.contactRequest) {
            this.setState({ request: nextProps.contactRequest })
        }

    }

    sendContactMessage() {
        const { name, email, request } = this.state;

        if (name !== "" && email !== "" && request !== "") {
            console.log("pressed");
            this.props.sendMessage({ name, email, request });
        }
    }

    render() {
        return(
            <div className={"contact"}>
                <h1>Contact Us</h1>

                <div className={"contactRequestContainer"}>
                    <div className={"contactNameContainer"}>
                        <Input
                            label={"Name"}
                            type={"text"}
                            placeholder={"Monarchy Technology"}
                            value={this.props.contactName}
                            onChange={ e => this.props.contactRequestUpdated({ prop: 'contactName', value: e.target.value}) }
                            name={"name"}
                        />
                    </div>

                    <div className={"contactEmailContainer"}>
                        <Input
                            label={"Email"}
                            type={"text"}
                            placeholder={"user@monarchy.com"}
                            value={this.props.contactEmail}
                            onChange={ e => this.props.contactRequestUpdated({ prop: 'contactEmail', value: e.target.value}) }
                            name={'email'}
                        />
                    </div>

                    <div className={"contactRequestBox"}>
                        <textarea
                            style={{
                                color: '#000',
                                padding: '1%',
                                marginTop: "1%"
                            }}

                            placeholder={ "Type your message here..." }
                            value={this.props.contactRequest}
                            onChange={ e => this.props.contactRequestUpdated({ prop: 'contactRequest', value: e.target.value}) }
                            name={'contactRequest'}
                        />
                    </div>

                    <Button
                        text={"Send Message"}
                        onPress={() => this.sendContactMessage() }
                    />


                </div>


                <div className={"locationMap"}>
                    <LocationMap/>
                </div>



            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { contactRequest, contactName, contactEmail } = state.contact;

    return {
        contactRequest,
        contactName,
        contactEmail
    };

};

export default connect(mapStateToProps, { contactRequestUpdated, sendMessage })(Contact);