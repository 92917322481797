import React from 'react';
import { connect } from 'react-redux';
import ImageUploader from 'react-images-upload';
import Gallery from 'react-grid-gallery';
import _ from 'lodash';

//css imports
import 'antd/es/radio/style/css';

//action imports
import { ourPartnersChanged, fetchOurPartnersImages, ourPartnersImageSelected, removeOurPartnersPictures } from "../../actions/PanelActions";

//component imports
import { Button } from "../common/Index";

class OurPartnersPanel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ourPartnersPictures: [],
            currentOurPartnersPictures: [],
            selectedOurPartnersImages: this.props.selectedOurPartnersImages,
        };


        this.onGalleryDrop = this.onGalleryDrop.bind(this);
        this.uploadGalleryPictures = this.uploadGalleryPictures.bind(this);
        this.onGalleryRemove = this.onGalleryRemove.bind(this);
        this.onGallerySelectImage = this.onGallerySelectImage.bind(this);

    }

    componentWillMount() {
        //fetch the current gallery images
        this.props.fetchOurPartnersImages();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        //check if current gallery pictures in next props is different
        if(nextProps.currentOurPartnersPictures !== this.props.currentOurPartnersPictures) {
            let currentOurPartnersPicturesArray = _.map(nextProps.currentOurPartnersPictures, imageObj => {
                const image = {
                    src: imageObj.downloadURL,
                    thumbnail: imageObj.downloadURL,
                    thumbnailWidth: 320,
                    thumbnailHeight: 174,
                    fileName: imageObj.fileName,
                    imageID: imageObj.imageID,
                };
                return image;
            });

            this.setState({ currentOurPartnersPictures: currentOurPartnersPicturesArray })
        }


        if(nextProps.selectedOurPartnersImages !== this.props.selectedOurPartnersImages) {
            this.setState({ selectedOurPartnersImages: nextProps.selectedOurPartnersImages })
        }

    }

    onGalleryDrop(picture) {
        this.setState({
            ourPartnersPictures: this.state.ourPartnersPictures.concat(picture),
        });
    }

    onGalleryRemove() {
        const { selectedOurPartnersImages } = this.state;
        console.log("selected images sent to actions");
        console.log(selectedOurPartnersImages);

        if (selectedOurPartnersImages) {
            this.props.removeOurPartnersPictures(selectedOurPartnersImages);
        } else {
            console.log("select images to remove")
        }
    }

    uploadGalleryPictures() {
        //take images from state and upload them
        const imageArray = this.state.ourPartnersPictures;

        //if there is a similar image in the array, delete duplicate
        let uniqueImagesArray = [...new Set(imageArray)];

        //send images to actions
        console.log("images sent to firebase");
        console.log(uniqueImagesArray);
        this.props.ourPartnersChanged(uniqueImagesArray);
    }

    onGallerySelectImage(index, image) {
        console.log(image);
        let images = this.state.currentOurPartnersPictures.slice();

        let img = images[index];

        //check that array is not empty
        if (img) {
            if (img.hasOwnProperty("isSelected"))
                img.isSelected = !img.isSelected;
            else
                img.isSelected = true;
        }

        if (img.hasOwnProperty("isSelected")) {
            if(img.isSelected) {

                //put selected image in an array
                let selectedImages = this.state.selectedOurPartnersImages;

                //check if array from state is not undefined
                if (selectedImages) {
                    //push the new selected image into array from state
                    selectedImages.push(img);
                    //set state with altered array
                    this.props.ourPartnersImageSelected(selectedImages);
                } else {
                    //selected images state was undefined so change to array
                    selectedImages = [];
                    //push the new selected image into array from state
                    selectedImages.push(img);

                    //undefined array changed to usable array now set it at state
                    this.props.ourPartnersImageSelected(selectedImages);
                }

            } else {
                //image is unselected

                //fetch ID of unselected image
                const id = img.imageID;
                //remove unselected image from selected array
                let selectedImages = this.state.selectedOurPartnersImages;

                //loop over each image in array to find an id that matches
                for (let i=0; i < selectedImages.length; i++) {
                    //if id matches, remove the element using index number
                    if ( selectedImages[i].imageID === id ) {
                        selectedImages.splice(i, 1);
                    }
                }

                //successfully removed image from selected array post new array to state
                this.props.ourPartnersImageSelected(selectedImages);
            }
        }
    }


    render() {

        return(
            <div className={"homePanel"}>

                <div className={"landingPageImages"}>

                    <div className={"galleryPageImagesContainer"}>
                        <h2>Partner Logos</h2>
                        <ImageUploader
                            withIcon={true}
                            buttonText='Choose images'
                            onChange={this.onGalleryDrop}
                            imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                            maxFileSize={52428800}
                        />
                        <div className={"galleryBtn"}>
                            <Button
                                text={'Upload'}
                                onPress={() => this.uploadGalleryPictures()}
                            />
                        </div>
                    </div>

                    <hr/>

                    <div className={"currentImages"}>
                        <h2>Manage Partner Logos</h2>
                        <div className={"currentImagesContainer"}>
                            <div className={"imagesContainer"}>
                                <h3>Current Partner Logos</h3>
                                <div className={"galleryBtn"}>
                                    <Button
                                        text={'Remove selected partners logos'}
                                        onPress={() => this.onGalleryRemove()}
                                    />
                                </div>

                                <Gallery
                                    images={this.state.currentOurPartnersPictures}
                                    enableImageSelection={true}
                                    onSelectImage={this.onGallerySelectImage}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    const { currentOurPartnersPictures, selectedOurPartnersImages } = state.panel;

    return {
        currentOurPartnersPictures,
        selectedOurPartnersImages
    }
};

export default connect(mapStateToProps, {ourPartnersChanged, fetchOurPartnersImages, ourPartnersImageSelected, removeOurPartnersPictures
})(OurPartnersPanel);