import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Gallery from 'react-grid-gallery';

//action imports
import { fetchGalleryImages } from "../actions/PanelActions";
//import { hideMobileNav } from "../actions/AppActions";

class GalleryPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            album: this.props.currentGalleryPictures
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.currentGalleryPictures !== this.props.currentGalleryPictures) {
            this.setState({ album: nextProps.currentGalleryPictures })
        }
    }

    componentWillMount() {
        this.props.fetchGalleryImages();
        //this.props.hideMobileNav();
    }

    render() {

        const album = _.map(this.state.album, imageObj => {
            return {
                src: imageObj.downloadURL,
                thumbnail: imageObj.downloadURL,
                thumbnailWidth: 320,
                thumbnailHeight: 174,
                fileName: imageObj.fileName,
                imageID: imageObj.imageID,
            };
        });

        return(
            <div  className={"galleryPage"}>
                <Gallery
                    images={album}
                    enableImageSelection={false}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { currentGalleryPictures } = state.panel;

    return {
        currentGalleryPictures
    }
};

export default connect(mapStateToProps, { fetchGalleryImages })(GalleryPage);